import React, { Component } from "react";
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import { withStyles } from '@material-ui/core/styles';

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import ReactTable from "../../../components/ReactTable/ReactTable.js";

import regularFormStyles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle.js";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { CSVLink } from "react-csv";

import Loader from 'react-loader-spinner';
const loaderStyle = { position: "fixed", top: "50%", left: "55%", transform: "translate(-50%, -50%)"}
const loader = (<div style={loaderStyle}> <Loader type="ThreeDots" color="#4cb5f5" width="200" height="200" /></div>);

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    ...regularFormStyles,
    ...alertStyles
};

class CustomerAuditLog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accountAuditLogData: null,
            dynamicScreenHeight: 0,
            alert: null
        };

        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        this.getAccountAuditLog(this.props.userId);
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        const margin = 120; // In my case was marginTop: 8px and marginBottom: 8px
        const heightSet = document.body.scrollHeight - margin;
        this.setState({ dynamicScreenHeight: heightSet });
    }

    getTime(time) {
        var localDate = new Date();
        var utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
        var now = moment(utcDate);
        var result = null;

        if (moment.duration(now.diff(new moment(time), 'years')) > 1) {
            result = moment.duration(now.diff(new moment(time), 'years')) + " years ago";
            if (result === "1 years ago") result = "1 year ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'hours')) > 24) {
            result = moment.duration(now.diff(new moment(time), 'days')) + " days ago";
            if (result === "1 days ago") result = "1 day ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'minutes')) > 60) {
            result = moment.duration(now.diff(new moment(time), 'hours')) + " hours ago";;
            if (result === "1 hours ago") result = "1 hour ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'seconds')) > 60) {
            result = moment.duration(now.diff(new moment(time), 'minutes')) + " minutes ago";
            if (result === "1 minutes ago") result = "1 minute ago";
        }
        else {
            result = moment.duration(now.diff(new moment(time), 'seconds')) + " seconds ago";
            if (result === "1 seconds ago") result = "1 second ago";
        }

        return result;
    }

    getAccountAuditLog = (userId) => {
        const bearerToken = localStorage.getItem("careXactSOSAdmin_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Account/AccountAuditLog?userId=' + userId, { headers })
                .then(response => response.json())
                .then(data => {
                    this.setState({ accountAuditLogData: data})
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showAlert(title, message) {
        const { classes } = this.props;
        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "1500px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                        {message}
                </SweetAlert>
        });
    }

    showSuccessAlert(title, message) {
        const { classes } = this.props;
        this.setState({
            alert:
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px", width: "600px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        {message}
                    </div>
                </SweetAlert>
        });
    }

    showErrorAlert(title, message) {
        const { classes } = this.props;
        this.setState({
            alert:
                <SweetAlert
                    error
                    style={{ display: "block", marginTop: "-100px", width: "600px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        {message}
                    </div>
                </SweetAlert>
        });
    }

    prepareCSVHeaders = () => {
        return [
            { label: 'When', key: 'When' },
            { label: 'Performed By', key: 'PerformedBy' },
            { label: 'Device Name', key: 'DeviceName' },
            { label: 'Action', key: 'LogAction' },
        ];
    };

    prepareCSVData = () => {
        const accountAuditLogData = this.state.accountAuditLogData;
        if (!accountAuditLogData || !accountAuditLogData.AccountAuditLog) {
            return [];
        }

        return accountAuditLogData.AccountAuditLog.map(logEntry => {
            return {
                When: this.getTime(logEntry.DateTimeCreated),
                PerformedBy: logEntry.ModifiedByUsername,
                DeviceName: logEntry.DeviceName,
                LogAction: logEntry.Action
            };
        });
    };

    render() {
        const { classes } = this.props;

        var accountAuditLogData = this.state.accountAuditLogData !== null ? this.state.accountAuditLogData : null;
        if (accountAuditLogData === null) { return (loader); }
        if (accountAuditLogData === undefined) { return (loader); }

        const headers = this.prepareCSVHeaders();
        const data = this.prepareCSVData();

        let emptyObjArray = [];
        var emptyObj = {
            UserName: null,
            Actions: null
        };
        emptyObjArray.push(emptyObj);

        return (
            <div>
                <div style={{ textAlign: 'left', overflow: 'auto', overflowX: 'hidden', height: this.state.dynamicScreenHeight + 'px' }}>
                    {accountAuditLogData.AccountAuditLog !== null ?
                        <GridContainer xs={12}>
                            {this.state.alert}
                            <GridItem xs={12}>
                                <Card>
                                    <CardBody>
                                        <div style={{ textAlign: 'right' }}>
                                            {/*<CSVLink data={data} headers={headers} filename={`Audit-Log-${this.props.username}.csv`}>Download</CSVLink>*/}
                                        </div>
                                        <ReactTable
                                            align="center"
                                            columns={[
                                                {
                                                    Header: "When",
                                                    accessor: "When",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "Performed By",
                                                    accessor: "PerformedBy",
                                                    disableFilters: false,
                                                    minWidth: 200
                                                },
                                                {
                                                    Header: "Device Name",
                                                    accessor: "DeviceName",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "Action",
                                                    accessor: "LogAction",
                                                    disableFilters: false,
                                                    minWidth: "50%"
                                                }
                                            ]}
                                            data={accountAuditLogData.AccountAuditLog.map((logEntry, key) => {
                                                return {
                                                    id: key,
                                                    When: this.getTime(logEntry.DateTimeCreated),
                                                    PerformedBy: logEntry.ModifiedByUsername,
                                                    DeviceName: logEntry.DeviceName,
                                                    LogAction: logEntry.Action
                                                };
                                            })}
                                        />
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                        :
                        <GridContainer>
                            {this.state.alert}
                            <GridItem xs={12}>
                                <Card>
                                    <CardBody>
                                        <div style={{ textAlign: 'right' }}>
                                            {/*<CSVLink data={data} headers={headers} filename={`Audit-Log-${this.props.username}.csv`}>Download</CSVLink>*/}
                                        </div>
                                        <ReactTable
                                            align="center"
                                            columns={[
                                                {
                                                    Header: "When",
                                                    accessor: "When",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "Performed By",
                                                    accessor: "PerformedBy",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "Device Name",
                                                    accessor: "DeviceName",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "Action",
                                                    accessor: "LogAction",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "",
                                                    accessor: "Actions",
                                                    disableFilters: true,
                                                }
                                            ]}
                                            data={emptyObjArray.map((prop, key) => {
                                                return {
                                                    id: key,
                                                    When: null,
                                                    PerformedBy: null,
                                                    DeviceName: null,
                                                    LogAction: null,
                                                    Actions: null
                                                };
                                            })}
                                        />
                                        <br /><small style={{ color: 'red' }}>No Account Audit Logs exist.</small>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    }
                </div>
                <Button color="rose" onClick={this.props.closeForm}>Close</Button>
            </div>
        );
    }
}

export default withStyles(style)(CustomerAuditLog);