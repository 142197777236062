import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import PlayIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import StopIcon from "@material-ui/icons/Stop";
import { withStyles } from '@material-ui/core/styles';

// material-ui components
import Tooltip from "@material-ui/core/Tooltip";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import ReactTable from "../../../components/ReactTable/ReactTable.js";

import { CSVLink } from "react-csv";

// tell webpack this js file uses the image
import PayPal from "../../../assets/img/paypal.png";

import regularFormStyles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle.js";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import Loader from 'react-loader-spinner';
const loaderStyle = { position: "fixed", top: "50%", left: "55%", transform: "translate(-50%, -50%)" }
const loader = (<div style={loaderStyle}> <Loader type="ThreeDots" color="#4cb5f5" width="200" height="200" /></div>);

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    ...regularFormStyles,
    ...alertStyles
};

const payalButtonStyle = { height: "35px", width: "90px", padding: "0px 0px 0px 0px", borderStyle: "none", borderRadius: "40px" }

class CustomerDeviceSubscriptions extends Component {
    interval = null;

    constructor(props) {
        super(props);

        this.state = {
            devicesData: null,
            selectedDevice: null,
            alert: null
        };
    }

    componentDidMount() {
        this.getDeviceData();
    }

    componentWillUnmount() {

    }

    getDeviceData = () => {
        const bearerToken = localStorage.getItem("careXactSOSAdmin_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/AllCustomerDevices', { headers })
                .then(response => response.json())
                .then(data => {
                    this.setState({ devicesData: data });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    getSubscriptionStatus = (id, status) => {
        if (id === null) return "Unsubscribed";
        if (id === undefined) return "Unsubscribed";
        if (status === 1) return "Active";
        if (status === 0) return "Paused";
    }

    updatePayPalSubscription = (classes, deviceId, subscriptionId, subscriptionTypePlanId, action, subscriptionChangeReason) => {

        const apiURL = process.env.REACT_APP_PAYPAL_API_BASE_URL;
        const authHeader = " Basic " + Buffer.from(process.env.REACT_APP_PAYPAL_CLIENT_ID + ":" + process.env.REACT_APP_PAYPAL_SECRET).toString('base64');
        const headers = { 'Accept': 'application/json', 'Accept-Language': 'en_US', 'Content-Type': 'application/x-www-form-urlencoded', 'Authorization': authHeader };

        if (subscriptionTypePlanId.toLowerCase() === "free") {
            this.updateDeviceSubscription(classes, deviceId, subscriptionId, subscriptionTypePlanId, action);
        } else {
            fetch(apiURL + 'v1/oauth2/token', {
                method: "POST",
                body: "grant_type=client_credentials",
                headers
            })
                .then(function (response) {
                    if (!response.ok) {
                        throw Error(response.status === 401 ? response.status + " : Unauthorized" : response.status + " : " + response.statusText);
                    }
                    return response;
                })
                .then(response => response.json())
                .then(data => {
                    const authBearerHeader = "Bearer " + data.access_token;

                    if (data.access_token === null) throw Error("Unable to fetch token from token server.  Check client credentials in .env file match the CareXact SOS PayPal application.");
                    if (data.access_token === undefined) throw Error("Unable to fetch token from token server.  Check client credentials in .env file match the CareXact SOS PayPal application.");

                    const oAuthHeaders = { 'Content-Type': 'application/json', 'Authorization': authBearerHeader };
                    var opertaionUrl = "";
                    var json;

                    if (action === "BILLING.SUBSCRIPTION.ACTIVATED") {
                        opertaionUrl = "v1/billing/subscriptions/" + subscriptionId + "/activate";
                        json = { reason: subscriptionChangeReason };
                    } else if (action === "BILLING.SUBSCRIPTION.SUSPENDED") {
                        opertaionUrl = "v1/billing/subscriptions/" + subscriptionId + "/suspend";
                        json = { reason: subscriptionChangeReason };
                    } else if (action === "BILLING.SUBSCRIPTION.CANCELLED") {
                        opertaionUrl = "v1/billing/subscriptions/" + subscriptionId + "/cancel";
                        json = { reason: subscriptionChangeReason };
                    }

                    if (opertaionUrl !== "") {
                        fetch(apiURL + opertaionUrl, { method: "POST", body: JSON.stringify(json), headers: oAuthHeaders })
                            .then(data => {
                                if (!data.ok) {
                                    throw Error(data.statusText);
                                } else {
                                    this.updateDeviceSubscription(classes, deviceId, subscriptionId, subscriptionTypePlanId, action);
                                }
                            })
                            .catch(error => {
                                this.showErrorAlert(classes, "PayPal Subscription API Error", error.message);
                            });
                    }
                })
                .catch(error => {
                    this.showErrorAlert(classes, "PayPal Subscription API Error", error.message);
                });
        }
    }

    updateDeviceSubscription = (classes, deviceId, subscriptionId, subscriptionTypePlanId, action) => {
        const bearerToken = localStorage.getItem("careXactSOSAdmin_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };
            var json = { DeviceId: deviceId, SubscriptionTypePlanId: subscriptionTypePlanId, PaypalSubscriptionId: subscriptionId, Action: action };

            fetch(apiURL + 'api/Subscription/UpdateSubscription', {
                method: "POST",
                body: JSON.stringify(json),
                headers: headers
            })
                .then(data => {
                    if (!data.ok) {
                        throw Error(data.statusText);
                    } else {
                        this.getDeviceData();
                        this.showSuccessAlert(classes, "Subscription change successful", "");
                    }
                })
                .catch(error => {
                    this.showErrorAlert(classes, "PayPal success. CareXact SOS update failed.", error.message);
                });
        }
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showSuccessAlert(classes, title, message) {
        this.setState({
            alert:
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px", width: "600px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        {message}
                    </div>
                </SweetAlert>
        });
    }

    showErrorAlert(classes, title, message) {
        this.setState({
            alert:
                <SweetAlert
                    error
                    style={{ display: "block", marginTop: "-100px", width: "600px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        {message}
                    </div>
                </SweetAlert>
        });
    }

    showConfirmAlert(classes, deviceId, subscriptionId, subscriptionTypePlanId, action) {
        var userFriendlyaction;
        var reason;
        var message1;
        var message2;
        var message3;
        if (action === "BILLING.SUBSCRIPTION.ACTIVATED") {
            userFriendlyaction = "activate";
            message1 = "Are you sure you want to reactivate the customers PayPal subscription?";
            message2 = "This will automatically reactivate the customers device.";
            reason = "CareXact has reactivated your subscription. For more information please contact CareXact on support@carexact.com.au";
        }
        if (action === "BILLING.SUBSCRIPTION.SUSPENDED") {
            userFriendlyaction = "pause";
            message1 = "Pausing a customers PayPal subscription will deactivate the customers device.";
            message2 = "A paused subscription CANNOT be reactivated by a customer.  A paused subscription CAN be reactivated by the administrator.";
            message3 = "When a paused subscription is reactivated the device is also reactivated automatically.";
            reason = "CareXact has paused your subscription. For more information please contact CareXact on support@carexact.com.au";
        }
        if (action === "BILLING.SUBSCRIPTION.CANCELLED") {
            userFriendlyaction = "cancel";
            message1 = "Cancelling a customers PayPal subscription is PERMANENT and CANNOT be reversed.";
            message2 = "A cancelled subscription CANNOT be reactivated and this will deactivate the customers device.";
            message3 = "After this subscription change, the customer will see a PayPal button next to the device in the Devices screen which they can use to attach a new PayPal subscription and reactivate the device.";
            reason = "CareXact has cancelled your subscription.  For more information please contact CareXact on support@carexact.com.au";
        }

        var confirmButtonText = "Yes, " + userFriendlyaction + " it!";
        var title = "Are you sure?"

        this.setState({
            alert:
                <SweetAlert
                    input
                    defaultValue={reason}
                    style={{ display: "block", marginTop: "-100px", width: "800px" }}
                    title={title}
                    onConfirm={(e) => this.updatePayPalSubscription(classes, deviceId, subscriptionId, subscriptionTypePlanId, action, e)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                    cancelBtnCssClass={classes.button + " " + classes.danger}
                    confirmBtnText={confirmButtonText}
                    cancelBtnText="Cancel"
                    showCancel
                >
                    <div style={{ textAlign: "justify" }}>
                        <ul>
                            <li>{message1}</li>
                            <li>{message2}</li>
                            <li>{message3}</li>
                        </ul>
                    </div>
                    <br />
                    <p style={{ color: "red" }}><b>CAUTION!!! The customer WILL see this reason.</b></p>
                </SweetAlert>
        });
    }

    prepareCSVHeaders = () => {
        return [
            { label: 'Username', key: 'UserName' },
            { label: 'Device Name', key: 'DeviceName' },
            { label: 'Product Code', key: 'ProductCode' },
            { label: 'IMEI', key: 'IMEI' },
            { label: 'Type', key: 'SubscriptionType' },
            { label: 'PayPal Id', key: 'SubscriptionId' },
            { label: 'Status', key: 'SubscriptionStatus' },
        ];
    };

    prepareCSVData = () => {
        const devicesData = this.state.devicesData;
        if (!devicesData || !devicesData.Devices) {
            return [];
        }

        return devicesData.Devices.map(prop => {
            var subscriptionStatus = this.getSubscriptionStatus(prop["PayPalSubscriptionId"], prop["PayPalSubscriptionActive"]);
            return {
                UserName: prop.UserName !== null ? prop.UserName : "",
                DeviceName: prop["DeviceName"],
                ProductCode: prop["ProductCode"],
                IMEI: prop["IMEI"],
                SubscriptionType: prop["SubscriptionType"],
                SubscriptionId: prop["PayPalSubscriptionId"] !== null ? prop["PayPalSubscriptionId"] : "",
                SubscriptionStatus: subscriptionStatus,
            };
        });
    };

    render() {
        const { classes } = this.props;

        var devicesData = this.state.devicesData !== null ? this.state.devicesData : null;
        if (devicesData === null) { return (loader); }
        if (devicesData === undefined) { return (loader); }

        const headers = this.prepareCSVHeaders();
        const data = this.prepareCSVData();

        let emptyObjArray = [];
        var emptyObj = {
            DeviceName: null,
            DeviceUser: null,
            FallEnabled: null,
            AuthorizedNumbers: null,
            BatteryLevel: null,
            Actions: null
        };
        emptyObjArray.push(emptyObj);

        return (
            devicesData.Devices !== undefined ?
                <GridContainer xs={12}>
                    {this.state.alert}
                    <GridItem xs={12}>
                        <Card>
                            <CardBody>
                                <div style={{ textAlign: 'right' }}>
                                    {/*<CSVLink data={data} headers={headers} filename={"PayPal-Subscriptions.csv"}>Download</CSVLink>*/}
                                </div>
                                <ReactTable
                                    align="center"
                                    columns={[
                                        {
                                            Header: "Username",
                                            accessor: "UserName",
                                            disableFilters: false,
                                            minWidth: 200
                                        },
                                        {
                                            Header: "Device Name",
                                            accessor: "DeviceName",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "Product Code",
                                            accessor: "ProductCode",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "Device",
                                            accessor: "DeviceType",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "IMEI",
                                            accessor: "IMEI",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "Subscription",
                                            accessor: "SubscriptionType",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "PayPal Id",
                                            accessor: "SubscriptionId",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "Status",
                                            accessor: "SubscriptionStatus",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "",
                                            accessor: "Actions",
                                            disableFilters: true,
                                        }
                                    ]}
                                    data={devicesData.Devices.map((prop, key) => {
                                        var subscriptionStatus = this.getSubscriptionStatus(prop["PayPalSubscriptionId"], prop["PayPalSubscriptionActive"]);
                                        return {
                                            id: key,
                                            UserName: prop.UserName !== null ? prop.UserName : "",
                                            DeviceName: prop["DeviceName"],
                                            ProductCode: prop["ProductCode"],
                                            DeviceType: prop["DeviceTypeDescription"],
                                            IMEI: prop["IMEI"],
                                            SubscriptionType: prop["SubscriptionType"],
                                            SubscriptionId: prop["PayPalSubscriptionId"] !== null ? prop["PayPalSubscriptionId"] : "",
                                            SubscriptionStatus: subscriptionStatus,
                                            Actions: (
                                                prop.UserName !== null ? <div className="actions-left">
                                                    <Tooltip title="Activate Subscription"><Button disabled={subscriptionStatus !== "Paused"} size="sm" justIcon color="rose" className={classes.marginRight} onClick={() => this.showConfirmAlert(classes, prop["DeviceId"], prop["PayPalSubscriptionId"], prop["SubscriptionTypePlanId"], "BILLING.SUBSCRIPTION.ACTIVATED")}><PlayIcon /></Button></Tooltip>
                                                    <Tooltip title="Pause Subscription"><Button disabled={subscriptionStatus === "Active" ? false : true} size="sm" justIcon color="rose" className={classes.marginRight} onClick={() => this.showConfirmAlert(classes, prop["DeviceId"], prop["PayPalSubscriptionId"], prop["SubscriptionTypePlanId"], "BILLING.SUBSCRIPTION.SUSPENDED")}><PauseIcon /></Button></Tooltip>
                                                    <Tooltip title="Cancel Subscription"><Button disabled={subscriptionStatus === "Active" || "Paused" ? subscriptionStatus === "Unsubscribed" ? true : false : true} size="sm" justIcon color="rose" className={classes, classes.marginRight} onClick={() => this.showConfirmAlert(classes, prop["DeviceId"], prop["PayPalSubscriptionId"], prop["SubscriptionTypePlanId"], "BILLING.SUBSCRIPTION.CANCELLED")}><StopIcon /></Button></Tooltip>
                                                </div>
                                                    : ""
                                            )
                                        };
                                    })}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                :
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardBody>
                                <div style={{ textAlign: 'right' }}>
                                    {/*<CSVLink data={data} headers={headers} filename={"PayPal-Subscriptions.csv"}>Download</CSVLink>*/}
                                </div>
                                <ReactTable
                                    align="center"
                                    columns={[
                                        {
                                            Header: "Customer Username",
                                            accessor: "UserName",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "Device Name",
                                            accessor: "DeviceName",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "Product Code",
                                            accessor: "ProductCode",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "Device",
                                            accessor: "DeviceType",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "Battery",
                                            accessor: "BatteryLevel",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "Subscription",
                                            accessor: "SubscriptionType",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "PayPal Id",
                                            accessor: "SubscriptionId",
                                            disableFilters: false
                                        },
                                        {
                                            Header: "",
                                            accessor: "Actions",
                                            disableFilters: true,
                                        }
                                    ]}
                                    data={emptyObjArray.map((prop, key) => {
                                        return {
                                            id: key,
                                            UserName: null,
                                            DeviceName: null,
                                            ProductCode: null,
                                            DeviceType: null,
                                            BatteryLevel: null,
                                            SubscriptionType: null,
                                            Subscription: null,
                                            Actions: null
                                        };
                                    })}
                                />
                                <br /><small style={{ color: 'red' }}>No devices exist.</small>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
        );
    }
}

export default withStyles(style)(CustomerDeviceSubscriptions);