import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import Add from "@material-ui/icons/Add";
import { withStyles } from '@material-ui/core/styles';

// material-ui components
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Tooltip from "@material-ui/core/Tooltip";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import ReactTable from "../../../components/ReactTable/ReactTable.js";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";

// custom components
import WizardAddDevice from "./WizardAddDevice.js";

import regularFormStyles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle.js";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { CSVLink } from "react-csv";

import Loader from 'react-loader-spinner';
const loaderStyle = { position: "fixed", top: "50%", left: "55%", transform: "translate(-50%, -50%)"}
const loader = (<div style={loaderStyle}> <Loader type="ThreeDots" color="#4cb5f5" width="200" height="200" /></div>);

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    ...regularFormStyles,
    ...alertStyles
};

class NewDevices extends Component {
    interval = null;

    constructor(props) {
        super(props);

        this.state = {
            devicesData: null,
            deviceTypeData: null,
            deviceTypeId: null,
            subscriptionTypeData: null,
            subscriptionTypeId: null,
            showAddDeviceWizardForm: false,
            refresh15: false,
            alert: null
        };
    }
    
    componentDidMount() {
        this.getDeviceData();
        this.getSubscriptionTypeData();
        this.getDeviceTypeData();
        this.interval = setInterval(this.getDeviceData, 30000);
    }

    componentWillUnmount() {
        this.setState({ showForm: false });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getDeviceData = () => {
        const bearerToken = localStorage.getItem("careXactSOSAdmin_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/AllNewDevices', { headers })
                .then(response => response.json())
                .then(data => {
                    this.setState({ devicesData: data });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    getSubscriptionTypeData = () => {
        const bearerToken = localStorage.getItem("careXactSOSAdmin_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Subscription/SubscriptionType', { headers })
                .then(response => response.json())
                .then(data => {
                    this.setState({ subscriptionTypeData: data });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    getDeviceTypeData = () => {
        const bearerToken = localStorage.getItem("careXactSOSAdmin_BearerToken");
        
        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/DeviceTypes', { headers })
                .then(response => response.json())
                .then(data => {
                    this.setState({ deviceTypeData: data });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    showAddDeviceWizardForm = () => {
        localStorage.removeItem("careXactSOSAdmin_Device");
        return (
            <WizardAddDevice closeForm={this.closeChildForm} closeFormNoRefresh={this.closeFormNoRefresh} subscriptionTypeId={this.state.subscriptionTypeId} deviceTypeId={this.state.deviceTypeId} />
        );
    }

    closeChildForm = () => {
        localStorage.removeItem("careXactSOSAdmin_Device");
        this.getDeviceData();
        this.setState({ showUpdateDeviceWizardForm: false, showAddDeviceWizardForm: false });
        window.scrollTo(0, 0);
        this.setState({ showAddDeviceWizardForm: true });   // toggle false - true to force render
    }

    closeFormNoRefresh = () => {
        localStorage.removeItem("careXactSOSAdmin_Device");
        this.setState({ showUpdateDeviceWizardForm: false, showAddDeviceWizardForm: false });
        window.scrollTo(0, 0);
    }

    countEnabledPhoneNumbers = (authorizedNumbers) => {

        if (authorizedNumbers === null) return 0;
        if (authorizedNumbers === undefined) return 0;

        var i = 0;
        var enabledPhoneNumberCount = 0;
        for (i = 0; i < authorizedNumbers.length; i++) {
            var authorizedNumber = authorizedNumbers[i];
            if (authorizedNumber.AuthorizedNumber.length > 0) {
                if (authorizedNumber.AcceptPhoneCall === "Y" || authorizedNumber.AcceptSMS === "Y")
                    enabledPhoneNumberCount++;
            }  
        }

        return enabledPhoneNumberCount;
    }

    handleCheckBoxChange = (event) => {
        this.setState({ refresh15: event.target.checked });

        if (event.target.checked === true) {
            clearInterval(this.interval);
            this.interval = setInterval(this.getDeviceData, 15000);
        }
        else {
            clearInterval(this.interval);
            this.interval = setInterval(this.getDeviceData, 30000);
        }
    };

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showSuccessAlert(title, message) {
        const { classes } = this.props;
        this.setState({
            alert:
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px", width: "600px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        {message}
                    </div>
                </SweetAlert>
        });
    }

    showErrorAlert(title, message) {
        const { classes } = this.props;
        this.setState({
            alert:
                <SweetAlert
                    error
                    style={{ display: "block", marginTop: "-100px", width: "600px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        {message}
                    </div>
                </SweetAlert>
        });
    }

    showDeleteConfirmAlert(productCode) {
        const { classes } = this.props;
        this.setState({
            alert:
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px", width: "800px" }}
                    title="UNPROVISION the device, are you sure?"
                    onConfirm={(e) => this.deleteClick(productCode)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                    cancelBtnCssClass={classes.button + " " + classes.danger}
                    confirmBtnText="Yes, UNPROVISION"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    <div style={{ textAlign: "justify" }}>
                        <ul>
                            <li>This will DELETE the device from the database completely.</li>
                            <li>The device MUST be reprovisioned with the IMEI, product code, and SIM serial number before it can be used again.</li>
                            <li><b>The customer cannot add the device to an account until the device is reprovisioned.</b></li>
                            <li>The device should only be unprovisioned after a customer return.</li>
                        </ul>
                    </div>
                    <br />
                    <p style={{ color: "red" }}><b>CAUTION!!! PERMANENT DEVICE REMOVAL</b></p>
                </SweetAlert>
        });
    }

    deleteClick = (productCode) => {

        var responseOk = true;
        const bearerToken = localStorage.getItem("careXactSOSAdmin_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/UnprovisionDevice?productCode=' + productCode, { method: 'DELETE', headers })
                .then(function (response) {
                    responseOk = response.ok;
                    return response.json();
                })
                .then((response) => {
                    if (responseOk) {
                        this.getDeviceData();
                        this.showSuccessAlert("Device UNPROVISIONED", "");
                    } else {
                        throw Error(response.Message);
                    }
                })
                .catch(error => {
                    this.showErrorAlert("", error);
                    console.log(error);
                }
                );
        }
    }

    formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber === null) return "";
        if (phoneNumber === undefined) return "";
        if (phoneNumber.substring(0, 1) !== "+") return "+" + phoneNumber;

        return phoneNumber;
    };

    prepareCSVHeaders = () => {
        return [
            { label: 'Product Code', key: 'ProductCode' },
            { label: 'IMEI', key: 'IMEI' },
            { label: 'Phone', key: 'Phone' },
            { label: 'Subscription Type', key: 'SubscriptionType' },
            { label: 'QA TCP', key: 'QATCP' },
        ];
    };

    prepareCSVData = () => {
        const devicesData = this.state.devicesData;
        if (!devicesData || !devicesData.Devices) {
            return []; // returns an empty array if devicesData or devicesData.Devices is null
        }

        return devicesData.Devices.map(prop => ({
            ProductCode: prop["ProductCode"],
            IMEI: prop["IMEI"],
            Phone: this.formatPhoneNumber(prop["DevicePhoneNumber"]),
            SubscriptionType: prop["SubscriptionType"],
            QATCP: prop["QATCP"] !== null ? prop["QATCP"] : "Testing...",
        }));
    };

    render() {
        const { classes } = this.props;

        var devicesData = this.state.devicesData !== null ? this.state.devicesData : null;
        var subscriptionTypeData = this.state.subscriptionTypeData;
        var deviceTypeData = this.state.deviceTypeData;
        if (devicesData === null) { return (loader); }
        if (devicesData === undefined) { return (loader); }
        if (subscriptionTypeData === null) { return (loader); };
        if (deviceTypeData === null) { return (loader); };

        const headers = this.prepareCSVHeaders();
        const data = this.prepareCSVData();

        let emptyObjArray = [];
        var emptyObj = {
            DeviceName: null,
            DeviceUser: null,
            FallEnabled: null,
            AuthorizedNumbers: null,
            BatteryLevel: null,
            Actions: null
        };
        emptyObjArray.push(emptyObj);

        if (this.state.showAddDeviceWizardForm) {
            window.scrollTo(0, 0);
            return this.showAddDeviceWizardForm();
        }
        else {
            return (
                devicesData.Devices !== undefined ?
                    <GridContainer xs={12}>
                        {this.state.alert}
                            <GridItem xs={12}>
                                <Card>
                                    <CardBody>
                                        <div style={{ display: 'flex', alignItems: 'center', width: '500px', border: '1px solid grey', borderRadius: '15px', padding: '10px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }} >
                                            <FormControl fullWidth className={classes.selectFormControl}>
                                                <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>Device</InputLabel>
                                                <Select
                                                    value={this.state.deviceTypeId}
                                                    onChange={(e) => this.setState({ deviceTypeId: e.target.value })}
                                                    MenuProps={{ className: classes.selectMenu }}
                                                    classes={{ select: classes.select }}
                                                    inputProps={{ name: "singleSelect", id: "single-select" }}>
                                                    <MenuItem disabled classes={{ root: classes.selectMenuItem }}>Device</MenuItem>
                                                    {deviceTypeData && deviceTypeData.DeviceType.map(deviceType => (
                                                        <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple }} value={deviceType.DeviceTypeId}>{deviceType.Description}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth className={classes.selectFormControl} style={{ marginLeft: 15 }}>
                                                <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>Subscription</InputLabel>
                                                <Select
                                                    value={this.state.subscriptionTypeId}
                                                    onChange={(e) => this.setState({ subscriptionTypeId: e.target.value })}
                                                    MenuProps={{ className: classes.selectMenu }}
                                                    classes={{ select: classes.select }}
                                                    inputProps={{ name: "singleSelect", id: "single-select" }}>
                                                    <MenuItem disabled classes={{ root: classes.selectMenuItem }}>Subscription</MenuItem>
                                                    {subscriptionTypeData && subscriptionTypeData.SubscriptionType.map(subscriptionType => (
                                                        <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple }} value={subscriptionType.SubscriptionTypeId}>{subscriptionType.Description}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Button
                                                style={{ marginLeft: '20px', marginTop: '20px' }}
                                                disabled={this.state.subscriptionTypeId === null || this.state.deviceTypeId === null}
                                                color="transparent"
                                                aria-label="Add Device"
                                                color="rose"
                                                size="sm"
                                                onClick={() => this.setState({ showAddDeviceWizardForm: true })}>
                                                <Add onClick={() => this.setState({ showAddDeviceWizardForm: true })} />Add Device
                                            </Button>
                                        </div>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox id="refresh15" tabIndex={-1} onClick={this.handleCheckBoxChange} checkedIcon={<Check className={classes.checkedIcon} />} icon={<Check className={classes.uncheckedIcon} />} checked={this.state.refresh15} classes={{ checked: classes.checked, root: classes.checkRoot }} />
                                                } classes={{ label: classes.label, root: classes.labelRoot }} label="Refresh 15 seconds" />
                                        </div>
                                        <hr />
                                        <div style={{ textAlign: 'right' }}>
                                        {/*<CSVLink data={data} headers={headers} filename={"New-Devices.csv"}>Download</CSVLink>*/}
                                        </div>
                                        <ReactTable
                                            align="center"
                                            columns={[
                                                {
                                                    Header: "Product Code",
                                                    accessor: "ProductCode",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "Device",
                                                    accessor: "DeviceType",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "IMEI",
                                                    accessor: "IMEI",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "Phone",
                                                    accessor: "Phone",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "Subscription",
                                                    accessor: "SubscriptionType",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "QA TCP",
                                                    accessor: "QATCP",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "",
                                                    accessor: "Actions",
                                                    disableFilters: true,
                                                }
                                            ]}
                                            data={devicesData.Devices.map((prop, key) => {
                                                return {
                                                    id: key,
                                                    ProductCode: prop["ProductCode"],
                                                    DeviceType: prop["DeviceTypeDescription"],
                                                    IMEI: prop["IMEI"],
                                                    Phone: this.formatPhoneNumber(prop["DevicePhoneNumber"]),
                                                    SubscriptionType: prop["SubscriptionType"],
                                                    SubscriptionId: prop["PayPalSubscriptionId"] !== null ? prop["PayPalSubscriptionId"] : "",
                                                    QATCP: prop["QATCP"] !== null ? prop["QATCP"] : "Testing...",
                                                    Actions: <div className="actions-left">
                                                                <Tooltip title="Unprovision Device"><Button size="sm" color="rose" className={classes.marginRight} onClick={() => this.showDeleteConfirmAlert(prop["ProductCode"])}>Unprovision</Button></Tooltip>
                                                            </div>
                                                };
                                            })}
                                        />
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>  
                    :
                    <GridContainer>
                        {this.state.alert}
                            <GridItem xs={12}>
                                <Card>
                                    <CardBody>
                                        <div style={{ display: 'flex', alignItems: 'center', width: '500px', border: '1px solid grey', borderRadius: '15px', padding: '10px', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)' }} >
                                            <FormControl fullWidth className={classes.selectFormControl}>
                                                <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>Device</InputLabel>
                                                <Select
                                                    value={this.state.deviceTypeId}
                                                    onChange={(e) => this.setState({ deviceTypeId: e.target.value })}
                                                    MenuProps={{ className: classes.selectMenu }}
                                                    classes={{ select: classes.select }}
                                                    inputProps={{ name: "singleSelect", id: "single-select" }}>
                                                    <MenuItem disabled classes={{ root: classes.selectMenuItem }}>Device</MenuItem>
                                                    {deviceTypeData && deviceTypeData.DeviceType.map(deviceType => (
                                                        <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple }} value={deviceType.DeviceTypeId}>{deviceType.Description}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <FormControl fullWidth className={classes.selectFormControl} style={{ marginLeft: 15 }}>
                                                <InputLabel htmlFor="multiple-select" className={classes.selectLabel}>Subscription Type</InputLabel>
                                                <Select
                                                    value={this.state.subscriptionTypeId}
                                                    onChange={(e) => this.setState({ subscriptionTypeId: e.target.value })}
                                                    MenuProps={{ className: classes.selectMenu }}
                                                    classes={{ select: classes.select }}
                                                    inputProps={{ name: "singleSelect", id: "single-select" }}>
                                                    <MenuItem disabled classes={{ root: classes.selectMenuItem }}>Subscription Type</MenuItem>
                                                    {subscriptionTypeData && subscriptionTypeData.SubscriptionType.map(subscriptionType => (
                                                        <MenuItem classes={{ root: classes.selectMenuItem, selected: classes.selectMenuItemSelectedMultiple }} value={subscriptionType.SubscriptionTypeId}>{subscriptionType.Description}</MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                            <Button
                                                style={{ marginLeft: '20px', marginTop: '20px' }}
                                            disabled={this.state.subscriptionTypeId === null || this.state.deviceTypeId === null}
                                                color="transparent"
                                                aria-label="Add Device"
                                                color="rose"
                                                size="sm"
                                                onClick={() => this.setState({ showAddDeviceWizardForm: true })}>
                                                <Add onClick={() => this.setState({ showAddDeviceWizardForm: true })} />Add Device
                                            </Button>
                                        </div>
                                        <div>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox id="refresh15" tabIndex={-1} onClick={this.handleCheckBoxChange} checkedIcon={<Check className={classes.checkedIcon} />} icon={<Check className={classes.uncheckedIcon} />} checked={this.state.refresh15} classes={{ checked: classes.checked, root: classes.checkRoot }} />
                                                } classes={{ label: classes.label, root: classes.labelRoot }} label="Refresh 15 seconds" />
                                        </div>
                                        <hr />
                                        <div style={{ textAlign: 'right' }}>
                                        {/*<CSVLink data={data} headers={headers} filename={"New-Devices.csv"}>Download</CSVLink>*/}
                                        </div>
                                        <ReactTable
                                            align="center"
                                            columns={[
                                                {
                                                    Header: "Product Code",
                                                    accessor: "ProductCode",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "Device",
                                                    accessor: "DeviceType",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "IMEI",
                                                    accessor: "IMEI",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "Phone",
                                                    accessor: "Phone",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "Subscription",
                                                    accessor: "SubscriptionType",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "QA TCP",
                                                    accessor: "QATCP",
                                                    disableFilters: false
                                                },
                                                {
                                                    Header: "",
                                                    accessor: "Actions",
                                                    disableFilters: true,
                                                }
                                            ]}
                                            data={emptyObjArray.map((prop, key) => {
                                                return {
                                                    id: key,
                                                    ProductCode: null,
                                                    DeviceType: null,
                                                    IMEI: null,
                                                    Phone: null,
                                                    SubscriptionType: null,
                                                    QATCP: null,
                                                    Actions: null
                                                };
                                            })}
                                        />
                                        <br/><small style={{ color: 'red' }}>No devices exist.  Add a device to your account.</small>
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
            );
        }
    }
}

export default withStyles(style)(NewDevices);