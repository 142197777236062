import React from "react";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import Wizard from "../../../components/Wizard/Wizard.js";
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";

import ChecklistStep from "./DeviceDetailsWizardSteps/Checklist.js";
import ProductCodeStep from "./DeviceDetailsWizardSteps/ProductCode.js";

import { makeStyles } from "@material-ui/core/styles";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useAlertStyles = makeStyles(alertStyles);

export default function WizardAddDevice(props) {
    const alertClasses = useAlertStyles();
    const subscriptionTypeId = props.subscriptionTypeId;
    const deviceTypeId = props.deviceTypeId;

    const hideAlert = () => {
        setAlert(null);
    };

    const [Saving, setSaving] = React.useState(false);
    const [alert, setAlert] = React.useState(null);

    const showAlert = (title, message) => {
        setAlert(
            <SweetAlert
                style={{ display: "block", marginTop: "-100px", width: "400px" }}
                title={title}
                onConfirm={() => hideAlert()}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={alertClasses.button + " " + alertClasses.success}>
                {message}
            </SweetAlert>
        );
    };

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    const handleFinish = async (event) => {
        if (Saving !== true) {
            var productCodeStep = event["productCode"];

            var phoneNumber = '';

            if (productCodeStep.PhoneNumber.trim().length === 1 && productCodeStep.PhoneNumber.trim() === '+')
                phoneNumber = '';
            else
                phoneNumber = productCodeStep.PhoneNumber.substring(1);

            const json = {
                ProductCode: productCodeStep.ProductCode,
                IMEI: productCodeStep.IMEI,
                SubscriptionTypeId: subscriptionTypeId,
                DeviceTypeId: deviceTypeId,
                SIMSN: productCodeStep.SIMSN,
                PhoneNumber: phoneNumber
            };

            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + localStorage.getItem("careXactSOSAdmin_BearerToken");
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            setSaving(true);

            await fetch(apiURL + 'api/Device/ProvisionDevice', {
                method: 'POST',
                body: JSON.stringify(json),
                headers: headers
            })
                .then(response => {
                    if (!response.ok)
                        return response.json().then(err => { throw Error(err.ExceptionMessage || 'Check the Product Code, IMEI, SIM Serial Number, and Phone Number are not already in use.') });

                    props.closeForm();
                })
                .catch(error => {
                    setSaving(false);
                    showAlert("Error", `The device could not be created. ${error.message}`);
                    console.log(error.message);
                });
        }
    };

    const handleClose = async (event) => {
        props.closeFormNoRefresh();
    }

    return (
        <GridContainer justify="center">
            <GridItem xs={12} sm={12}>
                <Wizard
                    color="rose"
                    validate
                    steps={[
                        { stepName: "Product Code + IMEI", stepComponent: ProductCodeStep, stepId: "productCode" },
                    ]}
                    title="Prepare a new device"
                    subtitle=""
                    finishButtonClick={event => handleFinish(event)}
                    closeButtonClick={event => handleClose(event)}
                />
                {alert}
            </GridItem>
        </GridContainer>
    );
}
