import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardHeader from "../../../components/Card/CardHeader.js";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

const useStyles = makeStyles(styles);

export default function ActiveDevices() {
    const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
    const [activeDevices, setActiveDevices] = React.useState("");
    const [timestamp, setTimestamp] = React.useState("");
    const classes = useStyles();

    React.useEffect(() => {
    let id = setTimeout(function() {
            setCardAnimation("");
        }, 700);
        // Specify how to clean up after this effect:
        return function cleanup() {
            window.clearTimeout(id);
        };
    });

    React.useEffect(() => {
        getActiveDevicesData();
    }, []);
    function getActiveDevicesData() {
        const apiURL = process.env.REACT_APP_API_BASE_URL;
        const subscriptionKey = process.env.REACT_APP_API_SUBSCRIPTION_KEY;
        const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey };

        fetch(apiURL + 'api/Device/ActiveDevices', { headers })
            .then(response => response.text())
            .then(data => {
                setActiveDevices(data);
                setTimestamp(new Date().toLocaleString());
            })
            .catch(error => {
                setActiveDevices('unknown');
            });
    }

    return (
        <div className={classes.container}>
            <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
                <Card login className={classes[cardAnimaton]}>
                    <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color="danger">  
                        <h1 className={classes.cardTitle}>Tunstall</h1>
                        <h3 className={classes.cardTitle}>Gem4 SOS</h3>
                        <h4 className={classes.cardTitle}>Administration</h4>
                    </CardHeader>
                    <CardBody>
                        <div style={{fontSize: '20px', textAlign: 'center'}}>
                            Active Devices : {activeDevices}
                        </div>
                        <Button color="rose" simple size="lg" block onClick={getActiveDevicesData}>Refresh</Button>
                        <div style={{ position: 'absolute', bottom: '10px', left: '10px', fontSize: '12px' }}>
                            {timestamp}
                        </div>
                    </CardBody>         
                </Card>
            </GridItem>
            </GridContainer>
        </div>
    );
}