import React from "react";
import PropTypes from "prop-types";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import CustomInput from "../../../../components/CustomInput/CustomInput.js";

import validationFormStyles from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

import info from "../../../../assets/img/info.png";
import Fab from '@material-ui/core/Fab';
const infoButtonStyle = {
    margin: 0,
    top: '550',
    right: 70,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
};

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
    },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
    ...validationFormStyles
};

class DeviceNameStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DeviceName: ""
        };
    }

    componentDidMount() {
        var device = JSON.parse(localStorage.getItem("careXactSOSAdmin_Device"));

        if (device != undefined) {
            this.setState({
                DeviceName: device.DeviceName,
                Device: device,
                LastDeviceHeartbeatDateTime: device.DeviceLocation.LastDeviceHeartbeatDateTime
            });
        } else {
            this.isValidated();
        }
    }

    sendState() {
        return this.state;
    }

    isValidated() {
        return true;
    }

    render() {
        const { classes } = this.props;        

        return (
            <div>
                <Fab size="medium" color="white" aria-label="add" style={infoButtonStyle}>
                    <img src={info} height="60px" width="60px" />
                </Fab>
                <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                    <h4 className={classes.infoText}>You can give your device a new name here if you like.</h4>
                    <h4 className={classes.infoText}>
                        <span style={{ color: 'red', fontWeight: 'bold' }}>Last Heartbeat: </span>
                        {this.state.LastDeviceHeartbeatDateTime &&
                            new Date(this.state.LastDeviceHeartbeatDateTime.replace(' ', 'T') + 'Z').toLocaleString('en-US', {
                                weekday: 'long',
                                year: 'numeric',
                                month: 'long',
                                day: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true
                            })
                        }
                    </h4>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <CustomInput labelText="Device Name" id="DeviceName" formControlProps={{ fullWidth: true }}
                        inputProps={{
                            onChange: event => this.setState({ DeviceName: event.target.value }),
                            type: "text",
                            value: this.state.DeviceName,
                            autoComplete: "off"
                        }} />
                </GridItem>
                </GridContainer>
            </div>
        );
  }
}

DeviceNameStep.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(DeviceNameStep);
