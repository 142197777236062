import Buttons from "./views/Components/Buttons.js";
import Calendar from "./views/Calendar/Calendar.js";
import Charts from "./views/Charts/Charts.js";
import ErrorPage from "./views/Pages/ErrorPage.js";
import ExtendedForms from "./views//Forms/ExtendedForms.js";
import ExtendedTables from "./views/Tables/ExtendedTables.js";
import GridSystem from "./views/Components/GridSystem.js";
import Icons from "./views/Components/Icons.js";
import LockScreenPage from "./views/Pages/LockScreenPage.js";
import Notifications from "./views/Components/Notifications.js";
import Panels from "./views/Components/Panels.js";
import RTLSupport from "./views/Pages/RTLSupport.js";
import ReactTables from "./views/Tables/ReactTables.js";
import RegularForms from "./views/Forms/RegularForms.js";
import RegularTables from "./views/Tables/RegularTables.js";
import SweetAlert from "./views/Components/SweetAlert.js";
import Typography from "./views/Components/Typography.js";
import ValidationForms from "./views/Forms/ValidationForms.js";
import Widgets from "./views/Widgets/Widgets.js";
import Wizard from "./views/Forms/Wizard.js";
import TableList from "./views/TableList/TableList.js";


// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";
import LocationOn from "@material-ui/icons/LocationOn";
import People from "@material-ui/icons/People";
import AttachMoney from "@material-ui/icons/AttachMoney";
import ErrorIcon from "@material-ui/icons/Error";
import DevicesOther from "@material-ui/icons/DevicesOther";
import Accounts from "@material-ui/icons/PersonOutline";

// custom views
import ActiveDevices from "./views/SOSDevice/Accounts/ActiveDevices.js";
import LoginPage from "./views/SOSDevice/Accounts/LoginPage.js";
import SignupPage from "./views/SOSDevice/Accounts/SignupPage.js";
import ResetPasswordPage from "./views/SOSDevice/Accounts/ResetPassword.js";
import NewDevices from "./views/SOSDevice/Device/NewDevices.js";
import CustomerAccounts from "./views/SOSDevice/Device/CustomerAccounts.js";
import CustomerDevices from "./views/SOSDevice/Device/CustomerDevices.js";
import CustomerDeviceSubscriptions from "./views/SOSDevice/Device/CustomerDeviceSubscriptions.js";
import SystemStatus from "./views/SOSDevice/Status/SystemStatus.js";
import PricingPage from "./views/SOSDevice/Accounts/PricingPage.js";

var dashRoutes = [
    {
        path: "/System-Status",
        name: "System Status",
        icon: ErrorIcon,
        component: SystemStatus,
        layout: "/admin"
    },
    {
        path: "/New-Devices",
        name: "New Devices",
        icon: DevicesOther,
        component: NewDevices,
        layout: "/admin"
    },
    {
        collapse: true,
        name: "Customers",
        icon: People,
        state: "pageCollapse",
        views: [
            {
                path: "/Customer-Accounts",
                name: "Accounts",
                icon: Accounts,
                component: CustomerAccounts,
                layout: "/admin"
            },
            {
                path: "/Customer-Devices",
                name: "Devices",
                icon: DevicesOther,
                component: CustomerDevices,
                layout: "/admin"
            },
            {
                path: "/Customer-Device-Subscriptions",
                name: "PayPal Subscriptions",
                icon: AttachMoney,
                component: CustomerDeviceSubscriptions,
                layout: "/admin"
            },
        ]
    },
    {
        collapse: true,
        hidden: true,
        name: "Dev Features",
        icon: Image,
        state: "pageCollapse",
        views: [
            {
                collapse: true,
                name: "Pages",
                rtlName: "صفحات",
                icon: Image,
                state: "pageCollapse",
                views: [
                    {
                        path: "/Calendar",
                        name: "Appointments",
                        icon: DateRange,
                        component: Calendar,
                        layout: "/admin"
                    },
                    {
                        path: "/pricing-page",
                        name: "Pricing Page",
                        mini: "PP",
                        component: PricingPage,
                        layout: "/auth"
                    },
                    {
                        path: "/rtl-support-page",
                        name: "RTL Support",
                        mini: "RS",
                        component: RTLSupport,
                        layout: "/rtl"
                    },
                    {
                        path: "/login-page",
                        name: "Login Page",
                        mini: "L",
                        component: LoginPage,
                        layout: "/auth"
                    },
                    {
                        path: "/active-devices",
                        name: "Active Devices",
                        mini: "A",
                        component: ActiveDevices,
                        layout: "/auth"
                    },
                    {
                        path: "/reset-password",
                        name: "Reset Password",
                        mini: "R",
                        component: ResetPasswordPage,
                        layout: "/auth"
                    },
                    {
                        path: "/sign-up",
                        name: "Sign Up",
                        mini: "S",
                        component: SignupPage,
                        layout: "/auth"
                    },
                    {
                        path: "/pricing",
                        name: "Pricing",
                        mini: "P",
                        component: PricingPage,
                        layout: "/auth"
                    },
                    {
                        path: "/error-page",
                        name: "Error Page",
                        mini: "E",
                        component: ErrorPage,
                        layout: "/auth"
                    }
                ]
            },
            {
                collapse: true,
                name: "Components",
                rtlName: "المكونات",
                icon: Apps,
                state: "componentsCollapse",
                views: [
                    {
                        collapse: true,
                        name: "Multi Level Collapse",
                        rtlName: "انهيار متعدد المستويات",
                        mini: "MC",
                        rtlMini: "ر",
                        state: "multiCollapse",
                        views: [
                            {
                                path: "/buttons",
                                name: "Buttons",
                                rtlName: "وصفت",
                                mini: "B",
                                rtlMini: "ب",
                                component: Buttons,
                                layout: "/admin"
                            }
                        ]
                    },
                    {
                        path: "/buttons",
                        name: "Buttons",
                        rtlName: "وصفت",
                        mini: "B",
                        rtlMini: "ب",
                        component: Buttons,
                        layout: "/admin"
                    },
                    {
                        path: "/grid-system",
                        name: "Grid System",
                        rtlName: "نظام الشبكة",
                        mini: "GS",
                        rtlMini: "زو",
                        component: GridSystem,
                        layout: "/admin"
                    },
                    {
                        path: "/panels",
                        name: "Panels",
                        rtlName: "لوحات",
                        mini: "P",
                        rtlMini: "ع",
                        component: Panels,
                        layout: "/admin"
                    },
                    {
                        path: "/sweet-alert",
                        name: "Sweet Alert",
                        rtlName: "الحلو تنبيه",
                        mini: "SA",
                        rtlMini: "ومن",
                        component: SweetAlert,
                        layout: "/admin"
                    },
                    {
                        path: "/notifications",
                        name: "Notifications",
                        rtlName: "إخطارات",
                        mini: "N",
                        rtlMini: "ن",
                        component: Notifications,
                        layout: "/admin"
                    },
                    {
                        path: "/icons",
                        name: "Icons",
                        rtlName: "الرموز",
                        mini: "I",
                        rtlMini: "و",
                        component: Icons,
                        layout: "/admin"
                    },
                    {
                        path: "/typography",
                        name: "Typography",
                        rtlName: "طباعة",
                        mini: "T",
                        rtlMini: "ر",
                        component: Typography,
                        layout: "/admin"
                    }
                ]
            },
            {
                collapse: true,
                name: "Forms",
                rtlName: "إستمارات",
                icon: "content_paste",
                state: "formsCollapse",
                views: [
                    {
                        path: "/regular-forms",
                        name: "Regular Forms",
                        rtlName: "أشكال عادية",
                        mini: "RF",
                        rtlMini: "صو",
                        component: RegularForms,
                        layout: "/admin"
                    },
                    {
                        path: "/extended-forms",
                        name: "Extended Forms",
                        rtlName: "نماذج موسعة",
                        mini: "EF",
                        rtlMini: "هوو",
                        component: ExtendedForms,
                        layout: "/admin"
                    },
                    {
                        path: "/validation-forms",
                        name: "Validation Forms",
                        rtlName: "نماذج التحقق من الصحة",
                        mini: "VF",
                        rtlMini: "تو",
                        component: ValidationForms,
                        layout: "/admin"
                    },
                    {
                        path: "/wizard",
                        name: "Wizard",
                        rtlName: "ساحر",
                        mini: "W",
                        rtlMini: "ث",
                        component: Wizard,
                        layout: "/admin"
                    }
                ]
            },
            {
                collapse: true,
                name: "Tables",
                rtlName: "الجداول",
                icon: GridOn,
                state: "tablesCollapse",
                views: [
                    {
                        path: "/regular-tables",
                        name: "Regular Tables",
                        rtlName: "طاولات عادية",
                        mini: "RT",
                        rtlMini: "صر",
                        component: RegularTables,
                        layout: "/admin"
                    },
                    {
                        path: "/extended-tables",
                        name: "Extended Tables",
                        rtlName: "جداول ممتدة",
                        mini: "ET",
                        rtlMini: "هور",
                        component: ExtendedTables,
                        layout: "/admin"
                    },
                    {
                        path: "/react-tables",
                        name: "React Tables",
                        rtlName: "رد فعل الطاولة",
                        mini: "RT",
                        rtlMini: "در",
                        component: ReactTables,
                        layout: "/admin"
                    }
                ]
            },
            {
                path: "/widgets",
                name: "Widgets",
                rtlName: "الحاجيات",
                icon: WidgetsIcon,
                component: Widgets,
                layout: "/admin"
            }
            ]
      }
];
export default dashRoutes;
