import React, { Component } from "react";
import moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import HistoryIcon from "@material-ui/icons/Timeline";
import { withStyles } from '@material-ui/core/styles';

// material-ui components
import Tooltip from "@material-ui/core/Tooltip";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import ReactTable from "../../../components/ReactTable/ReactTable.js";

import CustomerAuditLog from "../Device/CustomerAudtLog.js";

import regularFormStyles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle.js";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { CSVLink } from "react-csv";

import Loader from 'react-loader-spinner';
const loaderStyle = { position: "fixed", top: "50%", left: "55%", transform: "translate(-50%, -50%)"}
const loader = (<div style={loaderStyle}> <Loader type="ThreeDots" color="#4cb5f5" width="200" height="200" /></div>);

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    ...regularFormStyles,
    ...alertStyles
};

class CustomerAccounts extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accountData: null,
            auditLogData: null,
            loadingAuditLog: false,
            showAuditLog: false,
            userId: null,
            username: null,
            alert: null
        };
    }

    componentDidMount() {
        this.getAccountData();
    }

    componentWillUnmount() {

    }

    getTime(time) {
        var localDate = new Date();
        var utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
        var now = moment(utcDate);
        var result = null;

        if (moment.duration(now.diff(new moment(time), 'years')) > 1) {
            result = moment.duration(now.diff(new moment(time), 'years')) + " years ago";
            if (result === "1 years ago") result = "1 year ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'hours')) > 24) {
            result = moment.duration(now.diff(new moment(time), 'days')) + " days ago";
            if (result === "1 days ago") result = "1 day ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'minutes')) > 60) {
            result = moment.duration(now.diff(new moment(time), 'hours')) + " hours ago";;
            if (result === "1 hours ago") result = "1 hour ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'seconds')) > 60) {
            result = moment.duration(now.diff(new moment(time), 'minutes')) + " minutes ago";
            if (result === "1 minutes ago") result = "1 minute ago";
        }
        else {
            result = moment.duration(now.diff(new moment(time), 'seconds')) + " seconds ago";
            if (result === "1 seconds ago") result = "1 second ago";
        }

        return result;
    }

    getAccountData = () => {
        const bearerToken = localStorage.getItem("careXactSOSAdmin_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Account/AllAccounts', { headers })
                .then(response => response.json())
                .then(data => {
                    this.setState({ accountData: data });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    getAuditLogFull = () => {
        this.setState({ loadingAuditLog: true });
        const bearerToken = localStorage.getItem("careXactSOSAdmin_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Account/AuditLogFull', { headers })
                .then(response => response.json())
                .then(data => {
                    this.setState({ auditLogData: data, loadingAuditLog: false });
                })
                .catch(error => {
                    console.log(error);
                    this.setState({ auditLogData: null, loadingAuditLog: false });
                });
        }
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showAlert(title, message) {
        const { classes } = this.props;
        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "1500px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                        {message}
                </SweetAlert>
        });
    }

    showSuccessAlert(title, message) {
        const { classes } = this.props;
        this.setState({
            alert:
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px", width: "600px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        {message}
                    </div>
                </SweetAlert>
        });
    }

    showErrorAlert(title, message) {
        const { classes } = this.props;
        this.setState({
            alert:
                <SweetAlert
                    error
                    style={{ display: "block", marginTop: "-100px", width: "600px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        {message}
                    </div>
                </SweetAlert>
        });
    }

    closeFormNoRefresh = () => {
        this.setState({ showAuditLog: false, userId: null });
        window.scrollTo(0, 0);
    }

    showAuditLog = () => {
        localStorage.setItem("careXactSOSAdmin_Device", JSON.stringify(this.state.selectedDevice));
        return (
            <CustomerAuditLog closeForm={this.closeFormNoRefresh} userId={this.state.userId} username={this.state.username} />
        );
    }

    prepareCSVHeaders = () => {
        return [
            { label: 'Username', key: 'UserName' },
            { label: 'Phone Number', key: 'PhoneNumber' },
        ];
    };

    prepareCSVData = () => {
        const accountData = this.state.accountData;
        if (!accountData || !accountData.Accounts) {
            return [];
        }

        return accountData.Accounts.map(prop => {
            return {
                UserName: prop.Username !== null ? prop.Username : "",
                PhoneNumber: prop.PhoneNumber !== null ? prop.PhoneNumber : "",
            };
        });
    };

    prepareAuditLogFullCSVHeaders = () => {
        return [
            { label: 'When', key: 'When' },
            { label: 'Performed By', key: 'PerformedBy' },
            { label: 'Device Name', key: 'DeviceName' },
            { label: 'Action', key: 'LogAction' },
        ];
    };

    prepareAuditLogFullCSVData = () => {
        const accountAuditLogData = this.state.auditLogData;
        if (!accountAuditLogData || !accountAuditLogData.AccountAuditLog) {
            return [];
        }

        return accountAuditLogData.AccountAuditLog.map(logEntry => {
            return {
                When: this.getTime(logEntry.DateTimeCreated),
                PerformedBy: logEntry.ModifiedByUsername,
                DeviceName: logEntry.DeviceName,
                LogAction: logEntry.Action
            };
        });
    };

    render() {
        const { classes } = this.props;

        var accountData = this.state.accountData !== null ? this.state.accountData : null;
        if (accountData === null) { return (loader); }
        if (accountData === undefined) { return (loader); }

        const auditLogFullheaders = this.prepareAuditLogFullCSVHeaders();
        const auditLogFullData = this.prepareAuditLogFullCSVData();
        const headers = this.prepareCSVHeaders();
        const data = this.prepareCSVData();

        let emptyObjArray = [];
        var emptyObj = {
            UserName: null,
            Actions: null
        };
        emptyObjArray.push(emptyObj);

        if (this.state.showAuditLog) {
            window.scrollTo(0, 0);
            return this.showAuditLog();
        }
        else {
            return (
                accountData.Accounts !== undefined ?
                    <GridContainer xs={12}>
                        {this.state.alert}
                        <GridItem xs={12}>
                            <Card>
                                <CardBody>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {/*<div style={{ textAlign: 'left' }}>
                                            <Button
                                                style={{ marginLeft: '-15px', marginRight: '20px' }}
                                                disabled={this.state.loadingAuditLog}
                                                color="transparent"
                                                aria-label="Extract Audit Log"
                                                color="rose"
                                                size="sm"
                                                onClick={() => this.getAuditLogFull()}>
                                                Extract Audit Log - All Accounts
                                            </Button>
                                            {(this.state.auditLogData && !this.state.loadingAuditLog) && <CSVLink data={auditLogFullData} headers={auditLogFullheaders} filename={"Audit-Log-Full.csv"}>Download Audit Log</CSVLink>}
                                        </div>
                                        <div style={{ textAlign: 'right', marginTop: '10px' }}>
                                            <CSVLink data={data} headers={headers} filename={"Customer-Accounts.csv"}>Download Accounts</CSVLink>
                                        </div>*/}
                                    </div>
                                    <ReactTable
                                        align="center"
                                        columns={[
                                            {
                                                Header: "",
                                                accessor: "UserName",
                                                disableFilters: false,
                                                minWidth: 200
                                            },
                                            {
                                                Header: "",
                                                accessor: "PhoneNumber",
                                                disableFilters: false,
                                                minWidth: 200
                                            },
                                            {
                                                Header: "",
                                                accessor: "Actions",
                                                disableFilters: true
                                            }
                                        ]}
                                        data={accountData.Accounts.map((prop, key) => {
                                            return {
                                                id: key,
                                                UserName: prop.Username !== null ? prop.Username : "",
                                                PhoneNumber: prop.PhoneNumber !== null ? prop.PhoneNumber : "",
                                                Actions: (
                                                    <div className="actions-left">
                                                        <Tooltip title="Account Audit Log"><Button size="sm" color="rose" className={classes.marginRight} onClick={() => this.setState({ showAuditLog: true, userId: prop.Id, username: prop.Username })}><HistoryIcon />Account Audit Log</Button></Tooltip>
                                                    </div>
                                                )
                                            };
                                        })}
                                    />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    :
                    <GridContainer>
                        {this.state.alert}
                        <GridItem xs={12}>
                            <Card>
                                <CardBody>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        {/*<div style={{ textAlign: 'left' }}>
                                            <Button
                                                style={{ marginLeft: '-15px', marginRight: '20px' }}
                                                disabled={this.state.loadingAuditLog}
                                                color="transparent"
                                                aria-label="Extract Audit Log"
                                                color="rose"
                                                size="sm"
                                                onClick={() => this.getAuditLogFull()}>
                                                Extract Audit Log - All Accounts
                                            </Button>
                                            {(this.state.auditLogData && !this.state.loadingAuditLog) && <CSVLink data={auditLogFullData} headers={auditLogFullheaders} filename={"Audit-Log-Full.csv"}>Download Audit Log</CSVLink>}
                                        </div>
                                        <div style={{ textAlign: 'right', marginTop: '10px' }}>
                                            <CSVLink data={data} headers={headers} filename={"Customer-Accounts.csv"}>Download Accounts</CSVLink>
                                        </div>*/}
                                    </div>
                                    <ReactTable
                                        align="center"
                                        columns={[
                                            {
                                                Header: "",
                                                accessor: "UserName",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "",
                                                accessor: "Actions",
                                                disableFilters: true,
                                            }
                                        ]}
                                        data={emptyObjArray.map((prop, key) => {
                                            return {
                                                id: key,
                                                UserName: null,
                                                Actions: null
                                            };
                                        })}
                                    />
                                    <br /><small style={{ color: 'red' }}>No Accounts exist.</small>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
            );
        }
    }
}

export default withStyles(style)(CustomerAccounts);