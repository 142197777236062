import React from "react";
import PropTypes from "prop-types";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import CustomInput from "../../../../components/CustomInput/CustomInput.js";

import validationFormStyles from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

import info from "../../../../assets/img/info.png";
import Fab from '@material-ui/core/Fab';
const infoButtonStyle = {
    margin: 0,
    top: '550',
    right: 70,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
};

const style = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center"
    },
  inputAdornmentIcon: {
    color: "#555"
  },
  inputAdornment: {
    position: "relative"
  },
    ...validationFormStyles
};

class ChecklistStep extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.isValidated();
    }

    sendState() {
        return this.state;
    }

    verifyLength(value, length) {
        if (value.length >= length) {
          return true;
        }
        return false;
      }

    isValidated() {
        return true;
    }

    render() {
        const { classes } = this.props;        

        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                        <h4 className={classes.infoText}>Checklist</h4>
                </GridItem>
                <ol>
                    <li>Have you installed the latest firmware on the device yet?  If not get it <a href="https://systalk-health.atlassian.net/wiki/spaces/SNOWBALL/pages/1015382017/SOS+Pendant+Config#Latest-Device-Firmware" target="_blank">here</a></li>
                    <li>Have you applied the Default Configuration to the pendant?  If not get it <a href="https://systalk-health.atlassian.net/wiki/spaces/SNOWBALL/pages/1015382017/SOS+Pendant+Config#Production-Devices" target="_blank">here</a> and follow <a href="https://systalk-health.atlassian.net/wiki/spaces/SNOWBALL/pages/1015382017/SOS+Pendant+Config#Apply-Device-Configuration">these instructions</a></li>
                    <li>Click Finish to create the device, or Close to abort this device.</li>
                    <li>After clicking Finish Press the SOS Button on the device to test.  You may continue adding more devices whilst waiting for a result.</li>
                    <li>The device is ready once the QA SMS and QA TCP fields both show Pass.</li>
                </ol>
            </GridContainer>
        );
  }
}

ChecklistStep.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(ChecklistStep);
