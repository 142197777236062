import React, { Component } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import DeleteIcon from "@material-ui/icons/DeleteForever";
import UpdateIcon from "@material-ui/icons/Edit";
import { withStyles } from '@material-ui/core/styles';

// material-ui components
import Tooltip from "@material-ui/core/Tooltip";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import ReactTable from "../../../components/ReactTable/ReactTable.js";

// custom components
import WizardUpdateDevice from "./WizardUpdateDevice.js";

import regularFormStyles from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle.js";
import alertStyles from "../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import { CSVLink } from "react-csv";

import Loader from 'react-loader-spinner';
const loaderStyle = { position: "fixed", top: "50%", left: "55%", transform: "translate(-50%, -50%)" }
const loader = (<div style={loaderStyle}> <Loader type="ThreeDots" color="#4cb5f5" width="200" height="200" /></div>);

const payalButtonStyle = { height: "35px", width: "90px", padding: "0px 0px 0px 0px", borderStyle: "none", borderRadius: "40px" }

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    ...regularFormStyles,
    ...alertStyles
};

class CustomerDevices extends Component {
    interval = null;

    constructor(props) {
        super(props);

        this.state = {
            devicesData: null,
            selectedDevice: null,
            showUpdateDeviceWizardForm: false,
            alert: null,
            isLoading: false,
        };
    }

    componentDidMount() {
        this.getDeviceData();
    }

    componentWillUnmount() {
        this.setState({ showForm: false });
    }

    getDeviceData = () => {
        const bearerToken = localStorage.getItem("careXactSOSAdmin_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/AllCustomerDevices', { headers })
                .then(response => response.json())
                .then(data => {
                    this.setState({ devicesData: data });
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    getSelectedDeviceData = async (device) => {
        const bearerToken = localStorage.getItem("careXactSOSAdmin_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = {
                'Content-Type': 'application/json',
                'Ocp-Apim-Subscription-Key': subscriptionKey,
                'Authorization': authHeader
            };

            try {
                const response = await fetch(apiURL + 'api/Device?deviceId=' + device.DeviceId, {
                    headers: headers
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                return data;  // Return the device data
            } catch (error) {
                console.log(error);
                throw error;  // Optionally rethrow the error to handle it elsewhere
            }
        } else {
            throw new Error('Bearer token is undefined');
        }
    }

    getDevice = async (device) => {
        this.setState({ isLoading: true });
        var deviceData = await this.getSelectedDeviceData(device);
        deviceData.DeviceName = device.DeviceName;
        localStorage.setItem("careXactSOSAdmin_Device", JSON.stringify(deviceData));
        this.setState({ isLoading: false, showUpdateDeviceWizardForm: true });;
    }

    closeChildForm = () => {
        localStorage.removeItem("careXactSOSAdmin_Device");
        this.getDeviceData();
        this.setState({ showUpdateDeviceWizardForm: false, showAddDeviceWizardForm: false });
        window.scrollTo(0, 0);
    }

    closeFormNoRefresh = () => {
        localStorage.removeItem("careXactSOSAdmin_Device");
        this.setState({ showUpdateDeviceWizardForm: false, showAddDeviceWizardForm: false });
        window.scrollTo(0, 0);
    }

    countEnabledPhoneNumbers = (authorizedNumbers) => {

        if (authorizedNumbers === null) return 0;
        if (authorizedNumbers === undefined) return 0;

        var i = 0;
        var enabledPhoneNumberCount = 0;
        for (i = 0; i < authorizedNumbers.length; i++) {
            var authorizedNumber = authorizedNumbers[i];
            if (authorizedNumber.AuthorizedNumber.length > 0) {
                if (authorizedNumber.AcceptPhoneCall === "Y" || authorizedNumber.AcceptSMS === "Y")
                    enabledPhoneNumberCount++;
            }
        }

        return enabledPhoneNumberCount;
    }

    launchPayment = (device) => {
        localStorage.setItem("careXactSOSAdmin_DeviceId", device.DeviceId);
        this.setState({ showSubscriptionWizardForm: true })
    }

    getSubscriptionStatus = (id, status) => {
        if (id === null) return "Unsubscribed";
        if (id === undefined) return "Unsubscribed";
        if (status === 1) return "Active";
        if (status === 0) return "Paused";
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showSuccessAlert(title, message) {
        const { classes } = this.props;
        this.setState({
            alert:
                <SweetAlert
                    success
                    style={{ display: "block", marginTop: "-100px", width: "600px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        {message}
                    </div>
                </SweetAlert>
        });
    }

    showErrorAlert(title, message) {
        const { classes } = this.props;
        this.setState({
            alert:
                <SweetAlert
                    error
                    style={{ display: "block", marginTop: "-100px", width: "600px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        {message}
                    </div>
                </SweetAlert>
        });
    }

    showDeleteConfirmAlert(productCode) {
        const { classes } = this.props;
        this.setState({
            alert:
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px", width: "800px" }}
                    title="REMOVE the device from customer account, are you sure?"
                    onConfirm={(e) => this.deleteClick(productCode)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={classes.button + " " + classes.success}
                    cancelBtnCssClass={classes.button + " " + classes.danger}
                    confirmBtnText="REMOVE from Customer Account"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    <div style={{ textAlign: "justify" }}>
                        <ul>
                            <li>This will remove the device from the customers account.</li>
                            <li>The customer can add the device back to their account using the product code.</li>
                            <li>The device can be added to another account after it's removed from the current customer account.</li>
                        </ul>
                    </div>
                </SweetAlert>
        });
    }

    deleteClick = (productCode) => {

        var responseOk = true;
        const bearerToken = localStorage.getItem("careXactSOSAdmin_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/DeleteDevice?productCode=' + productCode, { method: 'DELETE', headers })
                .then(function (response) {
                    responseOk = response.ok;
                    return response.json();
                })
                .then((response) => {
                    if (responseOk) {
                        this.getDeviceData();
                        this.showSuccessAlert("Device REMOVED from customer account", "");
                    } else {
                        throw Error(response.Message);
                    }
                })
                .catch(error => {
                    this.showErrorAlert("", error);
                    console.log(error);
                }
                );
        }
    }

    formatPhoneNumber = (phoneNumber) => {
        if (phoneNumber === null) return "";
        if (phoneNumber === undefined) return "";
        if (phoneNumber.substring(1) !== "+") return "+" + phoneNumber;

        return phoneNumber;
    };

    prepareCSVHeaders = () => {
        return [
            { label: 'Username', key: 'UserName' },
            { label: 'Device Name', key: 'DeviceName' },
            { label: 'Product Code', key: 'ProductCode' },
            { label: 'IMEI', key: 'IMEI' },
            { label: 'Phone', key: 'Phone' },
            { label: 'Battery', key: 'BatteryLevel' },
        ];
    };

    prepareCSVData = () => {
        const devicesData = this.state.devicesData;
        if (!devicesData || !devicesData.Devices) {
            return [];
        }

        return devicesData.Devices.map(prop => {
            return {
                UserName: prop.UserName !== null ? prop.UserName : "",
                DeviceName: prop["DeviceName"],
                ProductCode: prop["ProductCode"],
                IMEI: prop["IMEI"],
                Phone: this.formatPhoneNumber(prop["DevicePhoneNumber"]),
                BatteryLevel: prop.DeviceLocation !== null ? prop.DeviceLocation.BatteryLevel + "%" : "",
            };
        });
    };

    render() {
        const { classes } = this.props;

        var devicesData = this.state.devicesData !== null ? this.state.devicesData : null;
        if (devicesData === null) { return (loader); }
        if (devicesData === undefined) { return (loader); }

        const headers = this.prepareCSVHeaders();
        const data = this.prepareCSVData();

        let emptyObjArray = [];
        var emptyObj = {
            DeviceName: null,
            DeviceUser: null,
            FallEnabled: null,
            AuthorizedNumbers: null,
            BatteryLevel: null,
            Actions: null
        };
        emptyObjArray.push(emptyObj);

        if (this.state.showUpdateDeviceWizardForm) {
            window.scrollTo(0, 0);
            return <WizardUpdateDevice closeForm={this.closeChildForm} closeFormNoRefresh={this.closeFormNoRefresh} />
        }
        else {
            return (
                devicesData.Devices !== undefined ?
                    <GridContainer xs={12}>
                        {this.state.alert}
                        <GridItem xs={12}>
                            <Card>
                                <CardBody>
                                    <div style={{ textAlign: 'right' }}>
                                        {/*<CSVLink data={data} headers={headers} filename={"Customer-Devices.csv"}>Download</CSVLink>*/}
                                    </div>
                                    <ReactTable
                                        align="center"
                                        columns={[
                                            {
                                                Header: "Username",
                                                accessor: "UserName",
                                                disableFilters: false,
                                                minWidth: 200
                                            },
                                            {
                                                Header: "Device Name",
                                                accessor: "DeviceName",
                                                disableFilters: false,
                                                maxWidth: 250
                                            },
                                            {
                                                Header: "Product Code",
                                                accessor: "ProductCode",
                                                disableFilters: false,
                                                maxWidth: 150
                                            },
                                            {
                                                Header: "Device",
                                                accessor: "DeviceType",
                                                disableFilters: false,
                                                maxWidth: 150
                                            },
                                            {
                                                Header: "IMEI",
                                                accessor: "IMEI",
                                                disableFilters: false,
                                                maxWidth: 150
                                            },
                                            {
                                                Header: "Device Phone",
                                                accessor: "Phone",
                                                disableFilters: false,
                                                maxWidth: 150
                                            },
                                            {
                                                Header: "",
                                                accessor: "Actions",
                                                disableFilters: true,
                                            }
                                        ]}
                                        data={devicesData.Devices.map((prop, key) => {
                                            var subscriptionStatus = this.getSubscriptionStatus(prop["PayPalSubscriptionId"], prop["PayPalSubscriptionActive"]);
                                            return {
                                                id: key,
                                                UserName: prop.UserName !== null ? prop.UserName : "",
                                                DeviceName: prop["DeviceName"],
                                                ProductCode: prop["ProductCode"],
                                                DeviceType: prop["DeviceTypeDescription"],
                                                IMEI: prop["IMEI"],
                                                Phone: this.formatPhoneNumber(prop["DevicePhoneNumber"]),
                                                Actions: (
                                                    prop.UserName !== null ? <div className="actions-left">
                                                        <Tooltip title="Update Device"><Button size="sm" color="rose" className={classes.marginRight} onClick={() => this.getDevice(prop)}>Update</Button></Tooltip>
                                                        <Tooltip title="Remove Device from Customer Account"><Button size="sm" disabled={subscriptionStatus !== "Unsubscribed"} color="rose" className={classes.marginRight} onClick={() => this.showDeleteConfirmAlert(prop["ProductCode"])}>Remove</Button></Tooltip>
                                                    </div>
                                                        : ""
                                                )
                                            };
                                        })}
                                    />
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                    :
                    <GridContainer>
                        {this.state.alert}
                        <GridItem xs={12}>
                            <Card>
                                <CardBody>
                                    <div style={{ textAlign: 'right' }}>
                                        {/*<CSVLink data={data} headers={headers} filename={"Customer-Devices.csv"}>Download</CSVLink>*/}
                                    </div>
                                    <ReactTable
                                        align="center"
                                        columns={[
                                            {
                                                Header: "Username",
                                                accessor: "UserName",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "Device Name",
                                                accessor: "DeviceName",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "Product Code",
                                                accessor: "ProductCode",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "Device",
                                                accessor: "DeviceType",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "Phone",
                                                accessor: "Phone",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "Battery",
                                                accessor: "BatteryLevel",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "Subscription Type",
                                                accessor: "SubscriptionType",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "Subscription",
                                                accessor: "SubscriptionId",
                                                disableFilters: false
                                            },
                                            {
                                                Header: "",
                                                accessor: "Actions",
                                                disableFilters: true,
                                            }
                                        ]}
                                        data={emptyObjArray.map((prop, key) => {
                                            return {
                                                id: key,
                                                UserName: null,
                                                DeviceName: null,
                                                ProductCode: null,
                                                DeviceType: null,
                                                Phone: null,
                                                BatteryLevel: null,
                                                SubscriptionType: null,
                                                Subscription: null,
                                                Actions: null
                                            };
                                        })}
                                    />
                                    <br /><small style={{ color: 'red' }}>No devices exist.</small>
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
            );
        }
    }
}

export default withStyles(style)(CustomerDevices);