import React from "react";
import PropTypes from "prop-types";

// @material-ui/icons
import Close from "@material-ui/icons/Close";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import CustomInput from "../../../../components/CustomInput/CustomInput.js";

import validationFormStyles from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";

import info from "../../../../assets/img/info.png";
import Fab from '@material-ui/core/Fab';
const infoButtonStyle = {
    margin: 0,
    top: '550',
    right: 70,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
};

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    inputAdornment: {
        position: "relative"
    },
    ...validationFormStyles
};

class ProductCodeStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ProductCode: "",
            ProductCodeRequiredState: "",
            IMEI: "",
            IMEIRequiredState: "",
            SIMSN: "",
            SIMSNRequiredState: "",
            PhoneNumber: "+",
            PhoneNumberRequiredState: ""
        };
    }

    componentDidMount() {
        this.isValidated();
    }

    sendState() {
        return this.state;
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    verifyExactLength(value, length) {
        if (value.length == length) {
            return true;
        }
        return false;
    }

    change(event, stateName, type, stateNameEqualTo) {
        switch (type) {
            case "length":
                if (this.verifyLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "RequiredState"]: "success" });
                } else {
                    this.setState({ [stateName + "RequiredState"]: "error" });
                }
                break;
            case "exactLength":
                if (this.verifyExactLength(event.target.value, stateNameEqualTo)) {
                    this.setState({ [stateName + "RequiredState"]: "success" });
                } else {
                    this.setState({ [stateName + "RequiredState"]: "error" });
                }
                break;
            default:
                break;
        }
        this.setState({ [stateName]: event.target.value });
    }

    formatPhoneNumber = (string) => {
        let newString = string.match(/[0-9]{0,14}/g);

        if (newString === null) {
            return '';
        }

        // Join parts returned from RegEx match
        newString = newString.join('');

        // Start number with "+"
        newString = '+' + newString;

        // Limit length to 15 characters
        newString = newString.substring(0, 12);

        this.isValidPhoneNumber(newString);

        return newString;
    }

    isValidPhoneNumber(phoneNumber) {
        if (phoneNumber.trim() === '' || phoneNumber.trim() === '+') {
            this.setState({ PhoneNumberRequiredState: "success" });
            return true;
        }

        if (phoneNumber.trim().length > 1 && phoneNumber.trim().length < 12) {
            this.setState({ PhoneNumberRequiredState: "error" });
            return false;
        }

        if (phoneNumber.substring(0, 4) === "+614") {
            this.setState({ PhoneNumberRequiredState: "success" });
            return true;
        }

        return false;
    }

    isValidated() {
        if (this.state.ProductCodeRequiredState === "success" &&
            this.state.IMEIRequiredState === "success" &&
            this.state.SIMSNRequiredState === "success" &&
            this.isValidPhoneNumber(this.state.PhoneNumber.trim())) {
            return true;

        } else {
            if (this.state.ProductCodeRequiredState !== "success") {
                this.setState({ ProductCodeRequiredState: "error" });
            }
            if (this.state.IMEIRequiredState !== "success") {
                this.setState({ IMEIRequiredState: "error" });
            }
            if (this.state.SIMSNRequiredState !== "success") {
                this.setState({ SIMSNRequiredState: "error" });
            }
        }

        return false;
    }

    render() {
        const { classes } = this.props;

        return (
            <GridContainer justify="center">
                <GridItem xs={12} sm={12}>
                    <h4 className={classes.infoText}>Please enter the product code and IMEI.</h4>
                </GridItem>
                <GridItem xs={12} sm={6}>
                    <CustomInput labelText="Product Code" error={this.state.ProductCodeRequiredState === "error"} id="ProductCode" formControlProps={{ fullWidth: true }}
                        inputProps={{
                            autoFocus: true,
                            onChange: event => this.change(event, "ProductCode", "exactLength", 8),
                            type: "text",
                            value: this.state.ProductCode,
                            autoComplete: "off",
                            endAdornment: this.state.ProductCodeRequiredState === "error" ? (<InputAdornment position="end"><Close className={classes.danger} /></InputAdornment>) : (undefined)
                        }} />
                    <CustomInput labelText="IMEI" error={this.state.IMEIRequiredState === "error"} id="IMEI" formControlProps={{ fullWidth: true }}
                        inputProps={{
                            onChange: event => this.change(event, "IMEI", "exactLength", 15),
                            type: "text",
                            value: this.state.IMEI,
                            autoComplete: "off",
                            endAdornment: this.state.IMEIRequiredState === "error" ? (<InputAdornment position="end"><Close className={classes.danger} /></InputAdornment>) : (undefined)
                        }} />
                    <CustomInput labelText="SIM Serial Number" error={this.state.SIMSNRequiredState === "error"} id="SIMSN" formControlProps={{ fullWidth: true }}
                        inputProps={{
                            onChange: event => this.change(event, "SIMSN", "length", 13),
                            type: "text",
                            value: this.state.SIMSN,
                            autoComplete: "off",
                            endAdornment: this.state.SIMSNRequiredState === "error" ? (<InputAdornment position="end"><Close className={classes.danger} /></InputAdornment>) : (undefined)
                        }} />
                    <CustomInput labelText="SOS Pendant Phone Number - format e.g. +61492551986" error={this.state.PhoneNumberRequiredState === "error"} id="PhoneNumber" formControlProps={{ fullWidth: true }}
                        inputProps={{
                            onChange: event => this.setState({ PhoneNumber: this.formatPhoneNumber(event.target.value) }),
                            type: "text",
                            value: this.state.PhoneNumber,
                            autoComplete: "off",
                            endAdornment: this.state.PhoneNumberRequiredState === "error" ? (<InputAdornment position="end"><Close className={classes.danger} /></InputAdornment>) : (undefined)
                        }} />
                </GridItem>
            </GridContainer>
        );
    }
}

ProductCodeStep.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style)(ProductCodeStep);
