import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";

// @material-ui/icons
import Email from "@material-ui/icons/Email";
import LockOutline from "@material-ui/icons/LockOutlined";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import CustomInput from "../../../components/CustomInput/CustomInput.js";
import Button from "../../../components/CustomButtons/Button.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardHeader from "../../../components/Card/CardHeader.js";

import styles from "../../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";


const useStyles = makeStyles(styles);

export default function LoginPage() {
  const history = useHistory();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response.json();
    }

  React.useEffect(() => {
    let id = setTimeout(function() {
      setCardAnimation("");
    }, 700);
    // Specify how to clean up after this effect:
    return function cleanup() {
      window.clearTimeout(id);
    };
  });

    const [loading, setLoading] = useState(false);
    const [IsAdmin, setIsAdmin] = useState(false);
    const [error, setError] = useState(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleFormChanges = (event) => {
        if (event.target.id === "email") setEmail(event.target.value);
        if (event.target.id === "password") setPassword(event.target.value);
    };

    const onClick = (event) => {
        event.preventDefault();
        setLoading(true);

        const apiURL = process.env.REACT_APP_API_BASE_URL;
        const subscriptionKey = process.env.REACT_APP_API_SUBSCRIPTION_KEY;
        const headers = { 'Content-Type': 'text/plain', 'Ocp-Apim-Subscription-Key': subscriptionKey };
        
        fetch(apiURL + 'Token', {
            method: 'POST',
            body: "grant_type=password&username=" + email + "&password=" + password,
            headers: headers
        })
        .then(handleErrors)
        .then(response => {
                localStorage.setItem("careXactSOSAdmin_BearerToken", response.access_token);
                localStorage.setItem("careXactSOSAdmin_ApiURL", apiURL);
                localStorage.setItem("careXactSOSAdmin_SubscriptionKey", subscriptionKey);

            const authHeader = "Bearer " + response.access_token;
            const IsAdminHeaders = { 'Content-Type': 'text/plain', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Account/IsAdmin', {
                method: 'GET',
                headers: IsAdminHeaders
            })
                .then(response => {
                    if (!response.ok) {
                        setIsAdmin(false);
                        throw Error(response.statusText);
                    } else {
                        setIsAdmin(true);

                        setLoading(false);

                        if (localStorage.getItem("careXactSOSAdmin_LastURL") !== null)
                            history.replace(localStorage.getItem("careXactSOSAdmin_LastURL").replace(window.location.origin, ""));
                        else
                            history.push("/");
                    }
                })
                .catch(error => {
                    setLoading(false);
                    if (IsAdmin === false)
                        setError("Ask a CareXact admin to add your user account to the Admin role to gain access to the administration portal.");
                    else
                        setError("You are a member of the admin role but something went wrong. Please check your username and password then try again, or use the reset password feature above. Error : " + error);
                });
        })
        .catch(error => {
            localStorage.removeItem("careXactSOSAdmin_BearerToken");
            setLoading(false);
            if (IsAdmin === null) {
                setError("Something went wrong. Please check your username and password then try again, or use the reset password feature above.");
            }
            else {
                if (IsAdmin === false)
                    setError("Ask a CareXact admin to add your user account to the Admin role to gain access to the administration portal.");
                else
                    setError("You are a member of the admin role but something went wrong. Please check your username and password then try again, or use the reset password feature above.");
            }

            console.log(error.message);
        });
    }

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={6} md={4}>
         <form onChange={handleFormChanges}>
            <Card login className={classes[cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="danger">  
                    <h1 className={classes.cardTitle}>Tunstall</h1>
                    <h3 className={classes.cardTitle}>Gem4 SOS</h3>
                    <h4 className={classes.cardTitle}>Administration</h4>
              </CardHeader>
              <CardBody>
                <CustomInput
                  labelText="Email..."
                  id="email"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Email className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                              <LockOutline className={classes.inputAdornmentIcon} />
                        </InputAdornment>
                    ),
                    type: "password",
                    autoComplete: "off"
                  }}
                />
                <font color="#FFFFFF" size="1"><i>Build : {process.env.REACT_APP_BUILD_VERSION}</i></font>  
                <Button color="rose" simple size="lg" block onClick={onClick} disabled={loading}>
                    {loading ? "Verifying..." : "Log in"}
                </Button>
                {error && <><br /><small style={{ color: 'red' }}>{error}</small><br /></>}  
              </CardBody>         
            </Card>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}