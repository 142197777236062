import React, { Component } from "react";
import moment from 'moment';

// material-ui icons
import ListIcon from "@material-ui/icons/List";
import AlertIcon from "@material-ui/icons/Notifications";
import { withStyles } from '@material-ui/core/styles';

import FormControlLabel from "@material-ui/core/FormControlLabel";

// core components
import GridContainer from "../../../components/Grid/GridContainer.js";
import GridItem from "../../../components/Grid/GridItem.js";
import CardIcon from "../../../components/Card/CardIcon.js";
import Card from "../../../components/Card/Card.js";
import CardBody from "../../../components/Card/CardBody.js";
import CardHeader from "../../../components/Card/CardHeader.js";
import ReactTable from "../../../components/ReactTable/ReactTable.js";
import Check from "@material-ui/icons/Check";
import Checkbox from "@material-ui/core/Checkbox";
import Accordion from "../../../components/Accordion/Accordion.js";

// tell webpack this js file uses the image
import LogoMini from "../../../assets/img/logo_mini.png";
import LogoMiniRed from "../../../assets/img/logo_mini_red.png";

import style from "../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
//import style from "../../../assets/jss/material-dashboard-pro-react/views/regularFormsStyle.js";

import Loader from 'react-loader-spinner';
const loaderStyle = { position: "fixed", top: "50%", left: "55%", transform: "translate(-50%, -50%)"}
const loader = (<div style={loaderStyle}> <Loader type="ThreeDots" color="#4cb5f5" width="200" height="200" /></div>);

class SystemStatus extends Component {
    interval = null;

    constructor(props) {
        super(props);

        this.state = {
            data: null,
            subscriptionType: "",
            refresh15: false
        };
    }

    componentDidMount() {
        this.getDeviceData();
        this.interval = setInterval(this.getDeviceData, 300000);
    }

    componentWillUnmount() {
        this.setState({ showForm: false });
        clearInterval(this.interval);
    }

    getDeviceData = () => {
        const bearerToken = localStorage.getItem("careXactSOSAdmin_BearerToken");

        if (bearerToken !== undefined) {
            const apiURL = localStorage.getItem("careXactSOSAdmin_ApiURL");
            const authHeader = "Bearer " + bearerToken;
            const subscriptionKey = localStorage.getItem("careXactSOSAdmin_SubscriptionKey");
            const headers = { 'Content-Type': 'application/json', 'Ocp-Apim-Subscription-Key': subscriptionKey, 'Authorization': authHeader };

            fetch(apiURL + 'api/Device/SystemStatus', { headers })
                .then(response => response.json())
                .then(data => {
                    this.setState({ data: data });
                })
                .catch(error => {

                });
        }
    }

    handleCheckBoxChange = (event) => {
        this.setState({ refresh15: event.target.checked });

        if (event.target.checked === true) {
            clearInterval(this.interval);
            this.interval = setInterval(this.getDeviceData, 15000);
        }
        else {
            clearInterval(this.interval);
            this.interval = setInterval(this.getDeviceData, 300000);
        }
    };

    getTime = (time) => {
        var localDate = new Date();
        var utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
        var now = moment(utcDate);
        var result = null;

        if (moment.duration(now.diff(new moment(time), 'years')) > 1) {
            result = moment.duration(now.diff(new moment(time), 'years')) + " years ago";
            if (result === "1 years ago") result = "1 year ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'hours')) > 24) {
            result = moment.duration(now.diff(new moment(time), 'days')) + " days ago";
            if (result === "1 days ago") result = "1 day ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'minutes')) > 60) {
            result = moment.duration(now.diff(new moment(time), 'hours')) + " hours ago";;
            if (result === "1 hours ago") result = "1 hour ago";
        }
        else if (moment.duration(now.diff(new moment(time), 'seconds')) > 60) {
            result = moment.duration(now.diff(new moment(time), 'minutes')) + " minutes ago";
            if (result === "1 minutes ago") result = "1 minute ago";
        }
        else {
            result = moment.duration(now.diff(new moment(time), 'seconds')) + " seconds ago";
            if (result === "1 seconds ago") result = "1 second ago";
        }

        return result;
    }

    determineSystemStatus = (data) => {
        var devicesHeartbeatLast10Mins = data.ActiveDevices_Heartbeating_Last10Mins !== undefined ? data.ActiveDevices_Heartbeating_Last10Mins[0].Count : 0;

        if (devicesHeartbeatLast10Mins === 0) {
            return { isStatusGood: false, reason: "No heartbeats" };
        }

        if (data.ErrorLog === null) return { isStatusGood: true, reason: "Healthy" };

        var i = 0;
        for (i = 0; i < data.ErrorLog.length; i++) {
            var localDate = new Date();
            var utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset() * 60000);
            var utcNow = moment(utcDate);

            if (moment.duration(utcNow.diff(new moment(data.ErrorLog[i].DateTimeCreated), 'hours')) < 24) {
                return { isStatusGood: false, reason: "ErrorLog entries within last 24hrs" };
            }
        }

        return { isStatusGood: true, reason: "Healthy" }; 
    }

    render() {
        const { classes } = this.props;

        var data = this.state.data !== null ? this.state.data : null;
        if (data === null) { return (loader); }
        if (data === undefined) { return (loader); }

        //var systemStatus = this.determineSystemStatus(data);

        return (
                <Card>
                    <CardBody>
                            <FormControlLabel
                                control={
                                    <Checkbox id="refresh15" tabIndex={-1} onClick={this.handleCheckBoxChange} checkedIcon={<Check className={classes.checkedIcon} />} icon={<Check className={classes.uncheckedIcon} />} checked={this.state.refresh15} classes={{ checked: classes.checked, root: classes.checkRoot }} />
                                } classes={{ label: classes.label, root: classes.labelRoot }} label="Refresh 15 seconds" />
                            
                            <Accordion
                                active={0}
                                collapses={[
                                    {
                                        title: "Summary",
                                        content:
                                            <GridContainer>
                                                <GridItem xs={12} md={4}>
                                                    <Card>
                                                        <CardHeader color="blueSky" icon>
                                                            <CardIcon color="blueSky">
                                                                <ListIcon />
                                                            </CardIcon>
                                                            <h4 className={classes.cardIconTitle}>Device Summary</h4>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <ul>
                                                                <li>{data.ActiveDevices.Count} active devices</li>
                                                                <li><b>{data.ActiveDevices_Heartbeating_Last4Hrs !== undefined ? data.ActiveDevices_Heartbeating_Last4Hrs[0].Count : 0} {data.ActiveDevices_Heartbeating_Last4Hrs[0].Count === 1 ? "device" : "devices"} heartbeating in the last 4 hours</b></li>
                                                                <li>{data.ActiveDevices_Heartbeating_Last60Mins != undefined ? data.ActiveDevices_Heartbeating_Last60Mins[0].Count : 0} {data.ActiveDevices_Heartbeating_Last60Mins[0].Count === 1 ? "device" : "devices"} heartbeating in the last hour</li>
                                                                <li><font color="#FF0000">{data.ActiveDevices_NotHeartbeating_Last60Mins !== undefined ? data.ActiveDevices_NotHeartbeating_Last60Mins.Count : 0} active {data.ActiveDevices_NotHeartbeating_Last60Mins.Count === 1 ? "device" : "devices"} with no heartbeat for over an hour</font></li>
                                                            </ul>
                                                        </CardBody>
                                                    </Card>
                                                </GridItem>
                                                <GridItem xs={12} md={4}>
                                                    <Card>
                                                        <CardHeader color="blueSky" icon>
                                                            <CardIcon color="blueSky">
                                                                <AlertIcon />
                                                            </CardIcon>
                                                            <h4 className={classes.cardIconTitle}>Alert Activity Last 24 hours</h4>
                                                        </CardHeader>
                                                        <CardBody>
                                                            {data.Alerts_Last24Hrs !== undefined ?
                                                                <ReactTable
                                                                    align="center"
                                                                    columns={[
                                                                        {
                                                                            Header: "Alert Code",
                                                                            accessor: "AlertCode",
                                                                            disableFilters: true
                                                                        },
                                                                        {
                                                                            Header: "Count",
                                                                            accessor: "Count",
                                                                            disableFilters: true
                                                                        }
                                                                    ]}
                                                                    data={data.Alerts_Last24Hrs.map((prop, key) => {
                                                                        return {
                                                                            id: key,
                                                                            AlertCode: prop["AlertCode"],
                                                                            Count: prop["Count"]
                                                                        };
                                                                    })}
                                                                />
                                                                :
                                                                <div>
                                                                    <ReactTable
                                                                        align="center"
                                                                        columns={[
                                                                            {
                                                                                Header: "Alert Code",
                                                                                accessor: "AlertCode",
                                                                                disableFilters: true
                                                                            },
                                                                            {
                                                                                Header: "Count",
                                                                                accessor: "Count",
                                                                                disableFilters: true
                                                                            }
                                                                        ]}
                                                                        data={[].map((prop, key) => {
                                                                            return {
                                                                                id: key,
                                                                                AlertCode: null,
                                                                                Count: null
                                                                            };
                                                                        })}
                                                                    />
                                                                    <br /><small style={{ color: 'red' }}>No alerts...</small>
                                                                </div>
                                                            }
                                                        </CardBody>
                                                </Card>
                                                </GridItem>
                                                <GridItem xs={12} md={4}>
                                                    <Card>
                                                <CardHeader color="blueSky" icon>
                                                    <CardIcon color="blueSky">
                                                        <AlertIcon />
                                                    </CardIcon>
                                                    <h4 className={classes.cardIconTitle}>Alert Activity Total</h4>
                                                </CardHeader>
                                                <CardBody>
                                                    {(data.Alerts_Total !== null && data.Alerts_Total !== undefined) ?
                                                        <ReactTable
                                                            align="center"
                                                            columns={[
                                                                {
                                                                    Header: "Alert Code",
                                                                    accessor: "AlertCode",
                                                                    disableFilters: true
                                                                },
                                                                {
                                                                    Header: "Count",
                                                                    accessor: "Count",
                                                                    disableFilters: true
                                                                }
                                                            ]}
                                                            data={data.Alerts_Total.map((prop, key) => {
                                                                return {
                                                                    id: key,
                                                                    AlertCode: prop["AlertCode"],
                                                                    Count: prop["Count"]
                                                                };
                                                            })}
                                                        />
                                                        :
                                                        <div>
                                                            <ReactTable
                                                                align="center"
                                                                columns={[
                                                                    {
                                                                        Header: "Alert Code",
                                                                        accessor: "AlertCode",
                                                                        disableFilters: true
                                                                    },
                                                                    {
                                                                        Header: "Count",
                                                                        accessor: "Count",
                                                                        disableFilters: true
                                                                    }
                                                                ]}
                                                                data={[].map((prop, key) => {
                                                                    return {
                                                                        id: key,
                                                                        AlertCode: null,
                                                                        Count: null
                                                                    };
                                                                })}
                                                            />
                                                            <br /><small style={{ color: 'red' }}>No alerts...</small>
                                                        </div>
                                                    }
                                                </CardBody>
                                            </Card>
                                                </GridItem>
                                            </GridContainer>
                                    },
                                    {
                                        title: "No Heartbeat Last 4 Hours",
                                        content:
                                            <GridContainer xs={12}>
                                                <GridItem xs={12}>
                                                {(data.ActiveDevices_NotHeartbeating_Summary_Last4Hrs !== null && data.ActiveDevices_NotHeartbeating_Summary_Last4Hrs !== undefined) ?
                                                    <ReactTable
                                                        align="center"
                                                        columns={[
                                                            {
                                                                Header: "Device Name",
                                                                accessor: "DeviceName",
                                                                disableFilters: false
                                                            },
                                                            {
                                                                Header: "Product Code",
                                                                accessor: "ProductCode",
                                                                disableFilters: false
                                                            },
                                                            {
                                                                Header: "IMEI",
                                                                accessor: "IMEI",
                                                                disableFilters: false
                                                            },
                                                            {
                                                                Header: "",
                                                                accessor: "Actions",
                                                                disableFilters: true
                                                            }
                                                        ]}
                                                        data={data.ActiveDevices_NotHeartbeating_Summary_Last4Hrs.map((prop, key) => {
                                                            return {
                                                                id: key,
                                                                DeviceName: prop["DeviceName"],
                                                                ProductCode: prop["ProductCode"],
                                                                IMEI: prop["IMEI"],
                                                                Actions: null
                                                            };
                                                        })}
                                                    />
                                                    :
                                                    <div>
                                                        <ReactTable
                                                            align="center"
                                                            columns={[
                                                                {
                                                                    Header: "Device Name",
                                                                    accessor: "DeviceName",
                                                                    disableFilters: false
                                                                },
                                                                {
                                                                    Header: "Product Code",
                                                                    accessor: "ProductCode",
                                                                    disableFilters: false
                                                                },
                                                                {
                                                                    Header: "",
                                                                    accessor: "Actions",
                                                                    disableFilters: true
                                                                }
                                                            ]}
                                                            data={[].map((prop, key) => {
                                                                return {
                                                                    id: key,
                                                                    DeviceName: null,
                                                                    ProductCode: null,
                                                                    Actions: null
                                                                };
                                                            })}
                                                        />
                                                        <br /><small style={{ color: 'red' }}>All active devices are heartbeating...</small>
                                                    </div>
                                                }
                                                </GridItem>
                                            </GridContainer>
                                    },
                                ]}
                            />
                        </CardBody>
                </Card>
        );
    }
}

export default withStyles(style)(SystemStatus);