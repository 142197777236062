import React from "react";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridContainer from "../../../../components/Grid/GridContainer.js";
import GridItem from "../../../../components/Grid/GridItem.js";
import CustomInput from "../../../../components/CustomInput/CustomInput.js";

import validationFormStyles from "../../../../assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import alertStyles from "../../../../assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import PlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByPlaceId } from 'react-places-autocomplete';
import parser from 'australia-address-parser';

import info from "../../../../assets/img/info.png";
import Fab from '@material-ui/core/Fab';

import 'react-phone-input-2/lib/style.css'
import PhoneInput from 'react-phone-input-2'
import startsWith from 'lodash.startswith';

const infoButtonStyle = {
    margin: 0,
    top: '550',
    right: 60,
    bottom: 'auto',
    left: 'auto',
    position: 'fixed'
};

const style = {
    infoText: {
        fontWeight: "300",
        margin: "10px 0 30px",
        textAlign: "center"
    },
    inputAdornmentIcon: {
        color: "#555"
    },
    inputAdornment: {
        position: "relative"
    },
    ...alertStyles
};

const info_image_icon = new Image().src = "https://carexactsosprod.blob.core.windows.net/help/homeIcon.png";

class ContactDetailsStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            StreetNumber: "",
            StreetName: "",
            StreetType: "",
            Suburb: "",
            State: "",
            PostCode: "",
            Country: "",
            Active: "",
            BirthDate: "",
            AddressVal: "",
            AddressLatitude: "",
            AddressLongitude: "",
            alert: null,
            PhoneNumber: "",
            isPhoneNumberValid: true
        };
    }

    componentDidMount() {
        var device = JSON.parse(localStorage.getItem("careXactSOSAdmin_Device"));

        if (device) {
            this.setState({
                FirstName: device.Party?.FirstName,
                LastName: device.Party?.LastName,
                PhoneNumber: device.Party?.PhoneNumber,
                AddressVal: device.Party?.StreetNumber !== "" ? device.Party?.StreetNumber + " " + device.Party?.StreetName + " " + device.Party?.StreetType + ", " + device.Party?.Suburb + " " + device.Party?.State + ", " + device.Party?.Country : "",
                StreetNumber: device.Party?.StreetNumber,
                StreetName: device.Party?.StreetName,
                StreetType: device.Party?.StreetType,
                Suburb: device.Party?.Suburb,
                State: device.Party?.State,
                PostCode: device.Party?.PostCode,
                Country: device.Party?.Country,
                Active: device.Party?.Active,
                BirthDate: device.Party?.BirthDate,
                AddressLatitude: device.Party?.AddressLatitude,
                AddressLongitude: device.Party?.AddressLongitude
            });
        } else {
            this.isValidated();
        }
    }

    sendState() {
        return this.state;
    }

    verifyLength(value, length) {
        if (value.length >= length) {
            return true;
        }
        return false;
    }

    change(event, stateName) {
        this.setState({ [stateName]: event.target.value });
    }

    isValidated() {

        if (this.state.PhoneNumber !== '' && !this.state.isPhoneNumberValid) {
            const { classes } = this.props;
            this.showAlert(classes, "Invalid Phone Number", "If a user phone number is added, it must start with a valid country code.");
            return false; // if a user has added a phone number don't let them enter an invalid number
        }

        return true;
    }

    parseNZAddress = (address) => {
        try {
            const addressParts = address.split(',');

            // First part is the street number, street name, and street type
            const streetPart = addressParts[0]?.trim().split(' ') || [];

            const streetNumber = streetPart[0] || ''; // Safely get the street number or default to empty string
            const streetName = streetPart[1] || ''; // Safely get the street name or default to empty string
            const streetType = streetPart.slice(2).join(' ') || ''; // Safely get the street type or default to empty string

            // Safely handle missing parts in the address
            const suburb = addressParts[1]?.trim() || ''; // Suburb after the first comma
            const state = addressParts[2]?.trim() || ''; // State after the second comma
            const country = addressParts[3]?.trim() || ''; // Country after the third comma

            return {
                streetNumber,
                streetName,
                streetType,
                suburb,
                state,
                country
            };
        } catch (error) {
            // Catch any parsing errors and return an empty object
            console.error('Error parsing address:', error);
            return {};
        }
    }

    handleSelect = async (address, placeId) => {
        this.setState({ AddressVal: address });

        // Convert address to lowercase and check for "australia" or "new zealand"
        if (address.toLowerCase().includes("australia")) {
            const addressInfo = parser.parseLocation(address);

            this.setState({
                StreetNumber: addressInfo?.streetNumber || '',
                StreetName: addressInfo?.streetName || '',
                StreetType: addressInfo?.streetType || '',
                Suburb: addressInfo?.suburb || '',
                State: addressInfo?.state || '',
                Country: "Australia"
            });
        } else if (address.toLowerCase().includes("new zealand")) {

            const nzAddressInfo = this.parseNZAddress(address);

            this.setState({
                StreetNumber: nzAddressInfo?.streetNumber || '',
                StreetName: nzAddressInfo?.streetName || '',
                StreetType: nzAddressInfo?.streetType || '',
                Suburb: nzAddressInfo?.suburb || '',
                State: nzAddressInfo?.state || '',
                Country: nzAddressInfo.country
            });
        }

        await geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                this.setState({ AddressLatitude: latLng.lat, AddressLongitude: latLng.lng });
            })
            .catch(error => console.error('Error', error));

        var place = await geocodeByPlaceId(placeId);
        if (place != undefined)
            if (place.length > 0) {
                var postalCode = place[0].address_components.find(c => c.types.includes('postal_code')).short_name || {};
                this.setState({ PostCode: postalCode });
            }
    }

    handleAddressChange = (address) => {
        this.setState({ AddressVal: address });

        if (address === "") {
            this.setState({
                StreetNumber: "",
                StreetName: "",
                StreetType: "",
                Suburb: "",
                State: "",
                PostCode: "",
                Country: "",
                AddressLatitude: "",
                AddressLongitude: "",
            });
        }
    }

    hideAlert = () => {
        this.setState({ alert: null });
    }

    showInfo(classes) {
        this.setState({
            alert:
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px", width: "500px" }}
                    title={""}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div style={{ textAlign: "justify" }}>
                        <center><img src={info_image_icon} height="107px" width="107px" objectfit="contain" /></center><br />
                        <p>Enter the pendant wearers contact details.  This is the name, home address, and contact phone number of the person wearing the SOS pendant.</p>
                        <p>This information is optional.</p>
                    </div>
                </SweetAlert>
        });
    }

    showAlert(classes, title, message) {
        this.setState({
            alert:
                <SweetAlert
                    error
                    style={{ display: "block", marginTop: "-100px", width: "400px" }}
                    title={title}
                    onConfirm={this.hideAlert}
                    onCancel={this.hideAlert}
                    confirmBtnCssClass={
                        classes.button + " " + classes.success
                    }>
                    <div>
                        {message}
                    </div>
                </SweetAlert>
        });
    }

    handlePhoneNumberOnChange = (value, countryData) => {
        // if truthy then append a + so when we POST to db phone numbers have a +
        if (value) {
            value = value.startsWith('+') ? value : "+" + value;

            // format +610414999888 as +61414999888.  International format country dial code replaces the leading 0
            value = value.startsWith('+' + countryData.dialCode + '0') ? value.replace('+' + countryData.dialCode + '0', '+' + countryData.dialCode) : value;
        }

        const isPhoneValid = this.isPhoneNumberFormatValid(value, countryData);

        this.setState({
            PhoneNumber: value,
            isPhoneNumberValid: isPhoneValid
        });
    };

    isPhoneNumberFormatValid = (inputNumber, country) => {
        if (inputNumber === '') {
            return true; // Allow empty, not a mandatory field
        }

        if (country?.dialCode) {
            return startsWith(inputNumber, "+" + country.dialCode) || startsWith("+" + country.dialCode, inputNumber);
        }

        return false;
    };

    render() {
        const { classes } = this.props;

        return (
            <div>
                <Fab size="small" color="white" aria-label="add" style={infoButtonStyle}>
                    <img alt="" src={info} height="45" width="45" onClick={event => this.showInfo(classes)} />
                </Fab>
                {this.state.alert}
                <GridContainer justify="center">
                    <GridItem xs={8}>
                        <h4 className={classes.infoText}>Contact details for the pendant wearer</h4>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                        <CustomInput labelText="First Name" id="FirstName" formControlProps={{ fullWidth: false }}
                            inputProps={{
                                onChange: event => this.change(event, "FirstName"),
                                type: "text",
                                value: this.state.FirstName || '',
                                autoComplete: "off"
                            }} />
                        <CustomInput labelText="Last Name" id="LastName" formControlProps={{ fullWidth: false }}
                            inputProps={{
                                onChange: event => this.change(event, "LastName"),
                                type: "text",
                                value: this.state.LastName || '',
                                autoComplete: "off"
                            }} />
                    </GridItem>
                    <GridItem xs={12} />
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                        <PhoneInput
                            placeholder="Enter phone number"
                            autoFormat={false}
                            onlyCountries={['au', 'nz']}
                            value={this.state.PhoneNumber || ''}
                            onChange={(value, countryData) => {
                                // If the value is empty, set it directly without prepending the country code
                                if (!value || value.length === 1) {
                                    this.handlePhoneNumberOnChange(value, countryData);
                                    return;
                                }

                                // weird edge case where if a user types a number then selects a country the component doesn't add the country code automatically
                                if (countryData?.dialCode && !value.startsWith(countryData?.dialCode)) {
                                    value = `+${countryData.dialCode}${value}`;
                                }

                                this.handlePhoneNumberOnChange(value, countryData);
                            }}
                            countryCodeEditable={true}
                            isValid={(inputNumber, country, countries) => {
                                // Ensure a country code is selected
                                return countries.some((country) => {
                                    return (
                                        inputNumber.startsWith(country.dialCode) ||
                                        country.dialCode.startsWith(inputNumber)
                                    );
                                });
                            }}
                            inputStyle={{ width: '50%' }}
                        />
                    </GridItem>
                    <GridItem xs={12} />
                    <GridItem xs={12} sm={6} md={6} lg={6}>
                        <PlacesAutocomplete fetchDetails={true} value={this.state.AddressVal} onChange={val => this.handleAddressChange(val)} onSelect={this.handleSelect} debounce={500} searchOptions={{ types: ['address'], componentRestrictions: { country: ['au', 'nz'] }, }}>
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                    <input type="text" name="address" autoComplete="off" style={{
                                        paddingTop: '15px',
                                        border: "none", borderBottom: "1px solid lightgray", fontSize: "14px",
                                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                        fontWeight: "400",
                                        lineHeight: "1.42857",
                                        opacity: "1",
                                        color: "#495057",
                                        width: "100%"
                                    }}
                                        {...getInputProps({
                                            placeholder: 'Type address ...',
                                            className: 'contact-details-step'
                                        })}
                                    />
                                    <div>
                                        {loading && <div>Loading...</div>}
                                        {suggestions.map(suggestion => {
                                            const className = `py-1 px-2 ${suggestion.active ? 'suggestion-item--active' : 'suggestion-item'}`;
                                            // inline style for demonstration purpose
                                            const style = suggestion.active ? { backgroundColor: '#fafafa', cursor: 'pointer' } : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                            return (
                                                <div {...getSuggestionItemProps(suggestion, { className, style, })}>
                                                    <span>{suggestion.description}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

ContactDetailsStep.propTypes = {
    classes: PropTypes.object
};

export default withStyles(style, validationFormStyles)(ContactDetailsStep);
